function escapeRegExpSearchString(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&');
}

function createTextSearchMarkup(fragment, search, isCaseSensitive) {
  const flags = `g${!!isCaseSensitive ? '' : 'i'}`;

  search = escapeRegExpSearchString(search);
  search = RegExp(`(${search})`, flags);

  return fragment.replace(search, '<mark>$1</mark>');
}

function concatTextSearchMarkup(collector, fragment) {
  const regXTag = /^<[^>]+>$/;

  if (!regXTag.test(fragment)) {
    fragment = createTextSearchMarkup(
      fragment,
      collector.search,
      collector.isCaseSensitive
    );
  }
  collector.markup = [collector.markup, fragment].join(''); // concat.

  return collector;
}

export function getHighlightTextSearchMarkup(markup, search, isCaseSensitive) {
  //const regXSimpleMarkup = (/(?<tagStart><[^>]+>)(?<text>[^<]+)(?<tagEnd><\/[^>]+>)/g);
  const regXSimpleMarkup = /(<[^>]+>)([^<]+)(<\/[^>]+>)/g;

  return markup.split(regXSimpleMarkup).reduce(concatTextSearchMarkup, {
    isCaseSensitive,
    search,
    markup: '',
  }).markup;
}

export function getTooltip(name, description) {
  return `
  <div class="ui card" style="width: 240px; height="100px">
    <div class="content center" style="width: 240px; height="30px">
      <h3 class="ui header" style="margin:0; font-weight: 700; margin-left: 5px; margin-bottom: 5px;"><ul>${name}</ul></h3>
    </div>
    <div class="content" style="width: 240px; height="70px">
      <div class="ui small feed">
        <div class="event">
          <div class="content">
            <div style="font-size: 14px; text-align: left;">
              <Text>${description}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
}
