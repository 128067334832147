import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

// THEME
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  useBoolean,
  useMediaQuery,
} from '@chakra-ui/react';

// ICONS
import { FaRoute } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  resetCoursesStyle,
  resetPathStyle,
  setupMarker,
} from '../../utils/d3utils';
import PathInfoDrawer from '../drawer/PathInfoDrawer';
import {
  STR_PATH,
  STR_PATH_TYPE_1,
  STR_PATH_TYPE_2,
} from '../../utils/constants';
import { scrollContentTop } from '../../utils/utils';

function Paths(props) {
  const [value, setValue] = useState();
  const [currentPath, setCurrentPath] = useState({});
  const [links, setLinks] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [conceptualPaths, setConceptualPaths] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (props.paths) {
      let tempLearningPaths = [];
      let tempConceptualPaths = [];
      props.paths.forEach(path => {
        if (path.is_conceptual !== '1') {
          tempLearningPaths.push(path);
        } else if (path.is_conceptual === '1') {
          tempConceptualPaths.push(path);
        }
      });
      setLearningPaths(tempLearningPaths);
      setConceptualPaths(tempConceptualPaths);
    }
  }, [props.paths]);

  function closeDrawer() {
    props.closeCourseDrawer();
    setIsDrawerOpen(false);
  }

  function itemClick(path) {
    resetPathStyle();
    resetCoursesStyle();
    setValue(path);
    setCurrentPath(path);

    let links = computeLinks(path);

    setLinks(links);
    let learningPath = displayLearningPath(path, links);

    setIsDrawerOpen(true);
    scrollContentTop('.path-drawer__content');

    d3.selectAll('.course').each(function (d) {
      if (path.courses.includes(d.id)) {
        d3.select(this)
          .style('stroke', '#2D3748')
          .classed('learning-path-active', true);
      }
      return null;
    });
  }

  function computeLinks(path) {
    let links = [];
    path.courses.forEach((course, index) => {
      if (index === path.courses.length - 1) return;
      links.push({
        source: path.courses[index],
        target: path.courses[index + 1],
      });
    });
    return links;
  }

  function displayLearningPath(path, links) {
    // Check if it has already been created
    d3.selectAll('.learning-paths').remove();
    if (d3.select('#learning-path-' + path.id).empty()) {
      let learningPath = d3
        .select('.main-group')
        .append('g')
        .attr('id', `learning-path-${path.id}`)
        .attr('class', 'learning-paths active');

      links.forEach((link, index) => {
        let sourceSelection = d3.select('#course' + link.source);
        let targetSelection = d3.select('#course' + link.target);

        if (sourceSelection.empty() || targetSelection.empty()) return;

        let sourceCoords = [0, 0];
        let targetCoords = [0, 0];

        sourceSelection.each(d => {
          sourceCoords = [d.x, d.y];
        });

        targetSelection.each(d => {
          targetCoords = [d.x, d.y];
        });

        learningPath
          .append('line')
          .attr('id', `learning-path-${path.id}-link-${index}`)
          .attr('class', function () {
            return 'link';
          })
          .attr('x1', function () {
            d3.select(this).attr('y1', sourceCoords[1]);
            return sourceCoords[0];
          })
          .attr('x2', function () {
            d3.select(this).attr('y2', targetCoords[1]);
            return targetCoords[0];
          })
          .style('fill', 'black')
          .attr('stroke', '#2D3748')
          .attr('stroke-width', '7')
          .attr('opacity', 0)
          .style('display', 'hidden')
          .attr('marker-end', setupMarker(path, index))
          .on('mouseenter', function () {
            d3.select(this)
              .style('cursor', 'pointer')
              .transition()
              .duration(200)
              .style('stroke', '#718096');

            d3.select(`#arrow-learning-path-${path.id}-link-${index}`)
              .attr('cursor', 'pointer')
              .transition()
              .duration(200)
              .style('fill', '#718096');
          })
          .on('mouseout', function () {
            d3.select(this)
              .style('cursor', 'initial')
              .transition()
              .duration(200)
              .style('stroke', '#2D3748');

            d3.select(`#arrow-learning-path-${path.id}-link-${index}`)
              .attr('cursor', 'pointer')
              .transition()
              .duration(200)
              .style('fill', '#2D3748');
          })
          .on('click', () => setIsDrawerOpen(true));
      });

      displayLinks(path);

      return learningPath;
    } else {
      displayLinks(path);
    }
  }

  function displayLinks(path) {
    if (path.is_conceptual === '1') {
      console.log('Is conceptual!');
      d3.select(`#learning-path-${path.id}`)
        .selectAll('.link')
        .transition()
        .duration(300)
        .style('opacity', 1)
        .style('display', 'initial');

      return;
    }
    // Display the learning path
    d3.select(`#learning-path-${path.id}`)
      .selectAll('.link')
      .style('opacity', 1)
      .style('display', 'initial')
      .each(function (d, i) {
        var totalLength = d3.select(this).node().getTotalLength();

        d3.select(this)
          // Set the line pattern to be an long line followed by an equally long gap
          .attr('stroke-dasharray', totalLength + ' ' + totalLength)
          // Set the intial starting position so that only the gap is shown by offesetting by the total length of the line
          .attr('stroke-dashoffset', totalLength)
          // Then the following lines transition the line so that the gap is hidden...
          .transition()
          .delay(i * 700)
          .duration(600)
          .ease(d3.easeLinear) //Try linear, quad, bounce... see other examples here - http://bl.ocks.org/hunzy/9929724
          .attr('stroke-dashoffset', 0);

        d3.select(`#arrow-learning-path-${path.id}-link-${i}`)
          .attr('fill-opacity', 0)
          .style('opacity', 0)
          .style('display', 'initial')
          .transition()
          .delay(i * 700 + 600)
          .duration(100)
          .attr('fill-opacity', 1)
          .style('opacity', 1);
      });
  }

  return (
    <div>
      <>
        {props.paths && props.paths.length > 0 && (
          <Menu>
            <Tooltip
              isDisabled={isMobile}
              hasArrow
              label={`${STR_PATH}`}
              color="white"
              fontSize="md"
              placement="bottom"
            >
              <MenuButton
                onFocus={e => e.preventDefault()}
                colorScheme="gray.700"
                background="gray.700"
                color="white"
                variant="solid"
                shadow="md"
                as={Button}
                leftIcon={<FaRoute />}
                rightIcon={<ChevronDownIcon />}
                disabled={props.disabled}
              ></MenuButton>
            </Tooltip>

            <MenuList>
              {learningPaths.length > 0 && (
                <MenuGroup title={STR_PATH_TYPE_1}>
                  {props.paths &&
                    props.paths.map((path, index) => {
                      return path.is_conceptual !== '1' ? (
                        <MenuItem
                          className="learning-path__button"
                          fontSize="md"
                          onClick={e => {
                            closeDrawer();
                            itemClick(path);
                          }}
                          key={index}
                        >
                          {path.path_name}
                        </MenuItem>
                      ) : (
                        false
                      );
                    })}
                </MenuGroup>
              )}
              {conceptualPaths.length > 0 && (
                <MenuGroup title={STR_PATH_TYPE_2}>
                  {props.paths &&
                    props.paths.map((path, index) => {
                      return path.is_conceptual === '1' ? (
                        <MenuItem
                          className="learning-path__button"
                          fontSize="md"
                          onClick={e => {
                            closeDrawer();
                            itemClick(path);
                          }}
                          key={index}
                        >
                          {path.path_name}
                        </MenuItem>
                      ) : (
                        false
                      );
                    })}
                </MenuGroup>
              )}
              <MenuDivider />
              <MenuItem
                fontSize="md"
                onClick={() => {
                  resetPathStyle();
                  resetCoursesStyle();
                  closeDrawer();
                }}
              >
                Reset
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <PathInfoDrawer
          data={currentPath}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
        />
      </>
    </div>
  );
}

export default Paths;
