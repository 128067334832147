import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

// Theme
import {
  Text,
  Heading,
  Divider,
  Slide,
  Box,
  Flex,
  CloseButton,
  Container,
  Link,
  ListItem,
  ListIcon,
  List,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';

// Icons
import CourseInfo from '../course/CourseInfo';
import { resetCoursesStyle } from '../../utils/d3utils';
import { scrollContentTop } from '../../utils/utils';

function CourseInfoDrawer(props) {
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  const [titleHeight, setTitleHeight] = useState(0);

  useEffect(() => {
    d3.select('#tooltip').transition().duration(10).style('opacity', 0);
    let title = document.querySelector('.info-drawer__title');
    if (title) setTitleHeight(title.offsetHeight);
  }, [props.data]);

  return (
    <>
      <Slide
        direction="right"
        in={props.isDrawerOpen}
        style={
          isMobile
            ? { zIndex: 10, width: '450px', maxWidth: '100%' }
            : { zIndex: 10, width: '450px', maxWidth: '100%', top: '88px' }
        }
        sx={{
          zIndex: 10,
          width: '450px',
        }}
      >
        <Box
          id="course-info-drawer-container"
          position="relative"
          color="black"
          bg="white"
          shadow="md"
          width="450px"
          maxWidth="100%"
          height={isMobile ? '100vh' : 'calc(100vh - 88px)'}
        >
          <Flex className="info-drawer__title" align="start" p="20px" pt="40px">
            <Heading
              as="h3"
              size="xl"
              fontSize="30px"
              letterSpacing={'tighter'}
              maxW="100%"
              w="100%"
              style={{ wordSpacing: '3px' }}
            >
              {props.data.course_title}
            </Heading>
            <CloseButton
              className="info-drawer__close"
              colorScheme="gray.700"
              position="absolute"
              top="10px"
              right="10px"
              onClick={() => {
                scrollContentTop('.info-drawer__content');
                resetCoursesStyle();
                props.closeDrawer();
              }}
            />
          </Flex>
          <Flex
            className="info-drawer__content"
            align="start"
            height={
              isMobile
                ? `calc(100vh - ${titleHeight + 80}px)`
                : `calc(100vh - ${titleHeight + 105}px)`
            }
            sx={{
              '::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0,0,0,.3)',
                borderRadius: '24px',
              },
            }}
            overflowY="scroll"
          >
            <CourseInfo
              data={props.data}
              disabledFields={props.disabledFields}
            ></CourseInfo>
          </Flex>
        </Box>
      </Slide>
    </>
  );
}

export default CourseInfoDrawer;
