import React, { useEffect, useState } from 'react';

// THEME
import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import CourseInfo from '../course/CourseInfo';
import { STR_MAIN_SUBJECT } from '../../utils/constants';

function ComparisonModal(props) {
  useEffect(() => {}, [props.compareCourses]);
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        motionPreset="scale"
        scrollBehavior="inside"
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" size="xl" ml="1" letterSpacing={'tighter'}>
              {STR_MAIN_SUBJECT} Comparison
            </Heading>
          </ModalHeader>
          <CloseButton
            className="compare-modal__close"
            colorScheme="gray.700"
            position="absolute"
            top="20px"
            right="20px"
            onClick={() => {
              props.onClose();
            }}
          />
          <ModalBody p={isMobile ? 0 : 2}>
            {props.compareCourses && props.compareCourses.length === 2 && (
              <Flex
                w="100%"
                flexDir={isMobile ? 'column' : 'row'}
                justifyContent="center"
              >
                <Box w="100%">
                  <Heading
                    as="h3"
                    size="lg"
                    ml="5"
                    mb="3"
                    letterSpacing={'tighter'}
                    style={{ wordSpacing: '3px' }}
                  >
                    {props.compareCourses[0].course_title}
                  </Heading>
                  <CourseInfo
                    data={props.compareCourses[0]}
                    disabledFields={props.disabledFields}
                  />
                </Box>
                {isMobile ? (
                  <div
                    style={{
                      marginTop: '30px',
                      marginBottom: '20px',
                      height: '3px',
                      width: '100%',
                      backgroundColor: 'var(--chakra-colors-gray-700)',
                    }}
                  ></div>
                ) : null}
                <Box w="100%">
                  <Heading
                    as="h3"
                    size="lg"
                    ml="5"
                    mb="3"
                    letterSpacing={'tighter'}
                    style={{ wordSpacing: '3px' }}
                  >
                    {props.compareCourses[1].course_title}
                  </Heading>
                  <CourseInfo
                    data={props.compareCourses[1]}
                    disabledFields={props.disabledFields}
                  />
                </Box>
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={props.onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ComparisonModal;
