// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const components = extendTheme({
  components: {
    CloseButton: {
      variants: {
        _hover: {
          defaultProps: {
            size: 'lg', // default is md
            colorScheme: 'gray.700', // default is gray
          },
        },
      },
    },
  },
});

const fonts = {
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
};

// 3. extend the theme
const theme = extendTheme({ ...config, ...components, ...fonts });

export default theme;
