import * as d3 from 'd3';

export function createImages(images, width = 50, height = 50) {
  if (images) {
    images.forEach(image => {
      addImageToDefs(image, width, height);
    });
  }
}

function addImageToDefs(image, width = 50, height = 50) {
  if (!image) return;

  d3.select('defs')
    .append('pattern')
    .attr('x', 0)
    .attr('y', 0)
    .attr('height', '1')
    .attr('width', '1')
    .attr('id', function () {
      return image.post_title.toLowerCase();
    })
    .append('image')
    .attr('type', 'image/svg+xml')
    .attr('xlink:href', image.guid)
    .attr('x', 5)
    .attr('y', 5)
    .attr('width', width)
    .attr('height', height)
    .style('-webkit-transform-style', 'preserve-3d');
}

export function setupMarker(path, linkIndex) {
  d3.select('.marker-defs')
    .append('svg:marker')
    .attr('id', `arrow-learning-path-${path.id}-link-${linkIndex}`)
    .attr('class', 'arrow')
    .attr('viewBox', '0 -5 10 10')
    .attr('refX', 6) //so that it comes towards the center.
    .attr('markerWidth', 4)
    .attr('markerHeight', 3.5)
    .attr('orient', 'auto')
    .style('opacity', 0)
    .style('fill', '#2D3748')
    .append('svg:path')
    .attr('d', 'M0,-5L10,0L0,5');
  return `url(#arrow-learning-path-${path.id}-link-${linkIndex})`;
}

/*
          Returns the coordinates of the passed element regardless of the transormation that have been
          applied during the whole execution.
        */
export function getScreenCoords(x, y, ctm) {
  var xn = ctm.e + x * ctm.a + y * ctm.c;
  var yn = ctm.f + x * ctm.b + y * ctm.d;
  return { x: xn, y: yn };
}

export function getElementCoords(element, coords) {
  var ctm = element.getCTM(),
    x = ctm.e + coords.x * ctm.a + coords.y * ctm.c,
    y = ctm.f + coords.x * ctm.b + coords.y * ctm.d;
  return { x: x, y: y };
}

export function resetCoursesStyle() {
  let courses = d3.selectAll('.course');

  courses.style('stroke', function (d) {
    let _this = d3.select(this);
    _this.classed('active', false).classed('export', false);

    if (_this.classed('learning-path-active')) {
      _this.style('stroke-width', 1);
      return '#2D3748';
    }
    if (_this.classed('matchsearch')) {
      d3.select(this).style('stroke-width', 5);
      return 'red';
    }

    return null;
  });

  // .style('stroke-width', 1);
}

export function resetCourseStyle(id) {
  let course = d3.select(`#course${id}`);

  course.style('stroke', function (d) {
    let _this = d3.select(this);
    _this.classed('active', false);

    if (_this.classed('learning-path-active')) {
      return '#2D3748';
    }
    if (_this.classed('matchsearch')) {
      return 'red';
    }
    return null;
  });
  // .style('stroke-width', 1);
}

export function displayActivePaths() {
  d3.selectAll('.learning-paths').each(function (d) {
    let _this = d3.select(this);
    if (_this.classed('active')) {
      _this.selectAll('.link').style('opacity', 1).style('display', 'initial');
      _this.selectAll('.arrow').style('opacity', 1).style('display', 'initial');
    }
  });
}

export function resetPathStyle() {
  d3.selectAll('.learning-paths').classed('active', false);
  d3.selectAll('.link').style('opacity', 0).style('display', 'none');
  d3.selectAll('.arrow').style('opacity', 0).style('display', 'none');
  d3.selectAll('.course').classed('learning-path-active', false);
}
