import { Center, Flex, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

function NoMatch() {
  let location = useLocation();

  return (
    <>
      <Flex direction="column" bg="gray.700" h="100vh">
        <Center w="100" h="100" m="5">
          <Heading as="h1" color="white">
            No match for <code>{location.pathname}</code>
          </Heading>
        </Center>
      </Flex>
    </>
  );
}

export default NoMatch;
