import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import * as d3 from 'd3';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Input,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { resetCoursesStyle } from '../../utils/d3utils';
import { STR_MAIN_SUBJECT, STR_MAIN_SUBJECTS } from '../../utils/constants';

const Search = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  const onFocus = () => {
    setIsOpen(true);
  };

  const onBlur = () => {
    setIsOpen(false);
  };

  const onInputChange = event => {
    resetCoursesStyle();
    setSelected(null);
    setSearchText(event.target.value);

    setFilteredItems(
      props.data?.filter(item => {
        return item.course_title
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      })
    );

    let text = event.target.value.toLowerCase();

    d3.selectAll('.course').style('stroke', null).classed('matchsearch', false);
    resetCoursesStyle();

    if (text === '' && !selected) {
      console.log('It worked');
      d3.selectAll('.course').classed('matchsearch', false);
      return;
    }

    d3.selectAll('.course').each(function (d) {
      if (d.course_title.toLowerCase().includes(text)) {
        d3.select(this)
          .style('stroke', 'red')
          .style('stroke-width', 5)
          .classed('matchsearch', true);
      }
      return 'null';
    });
  };

  const onItemClick = item => {
    let courseElement = d3.select(`#course${item.id}`);
    d3.selectAll('.course').style('stroke', null).classed('matchsearch', false);
    courseElement.classed('matchsearch', true);
    resetCoursesStyle();
    setSearchText('');
    setSelected(item);

    if (!courseElement.empty()) courseElement.dispatch('click');
  };

  const onClear = () => {
    d3.selectAll('.course').classed('matchsearch', false);
    resetCoursesStyle();
    setSearchText('');
    setSelected(null);
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredItems(props.data);
    }
  }, [searchText, props.data]);

  // Extend this component so that any parent can call its defined methods
  useImperativeHandle(ref, () => ({
    clear() {
      onClear();
    },
  }));

  return (
    <div>
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onInputChange}
        pr="45px"
        background="white"
        value={selected ? selected.course_title : searchText}
        placeholder={`Search a ${STR_MAIN_SUBJECT.toLowerCase()}...`}
        size="md"
      />
      {(searchText || selected) && (
        <InputRightElement
          sx={{
            position: 'absolute',

            ':hover': {
              cursor: 'pointer',
            },
          }}
          disabled={searchText === ''}
          onClick={onClear}
          children={
            <CloseIcon
              sx={{
                width: '15px',
                height: '15px',
                background: 'var(--chakra-colors-gray-700)',
                borderRadius: '50%',
                padding: '2px',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            />
          }
        />
      )}
      {isOpen && (
        <Box
          background="white"
          boxShadow="md"
          maxHeight="250px"
          overflowY="scroll"
          w="100%"
          p={3}
          position="absolute"
        >
          <VStack spacing={0} align="stretch">
            {selected && (
              <div style={{ paddingBottom: '5px' }}>
                {filteredItems
                  .filter(item => {
                    return item.course_title === selected?.course_title;
                  })
                  .map(item => {
                    return (
                      <Box
                        onMouseEnter={() => props.onItemMouseEnter(item)}
                        onMouseLeave={() => props.onItemMouseLeave(item)}
                        onMouseDown={() => onItemClick(item)}
                        pt={1}
                        pb={1}
                        pl={3}
                        pr={3}
                        key={item.id}
                        borderRadius="5px"
                        fontSize="md"
                        fontWeight={'bold'}
                        textAlign="left"
                        _hover={{
                          backgroundColor: 'rgba(33,33,33, 0.1)',
                          cursor: 'pointer',
                        }}
                      >
                        {item.course_title}
                      </Box>
                    );
                  })}
                <Divider borderColor="var(--chakra-colors-gray-700)" pt="5px" />
              </div>
            )}
            {filteredItems
              .filter(item => {
                return item.course_title !== selected?.course_title;
              })
              .map(item => {
                return (
                  <Box
                    onMouseEnter={() => props.onItemMouseEnter(item)}
                    onMouseLeave={() => props.onItemMouseLeave(item)}
                    onMouseDown={() => onItemClick(item)}
                    pt={1}
                    pb={1}
                    pl={3}
                    pr={3}
                    key={item.id}
                    borderRadius="5px"
                    fontSize="md"
                    fontWeight={'normal'}
                    textAlign="left"
                    _hover={{
                      backgroundColor: 'rgba(33,33,33, 0.1)',
                      cursor: 'pointer',
                    }}
                  >
                    {item.course_title}
                  </Box>
                );
              })}
            {filteredItems.length === 0 ? (
              <Box
                pt={1}
                pb={1}
                pl={3}
                pr={3}
                borderRadius="5px"
                fontSize="md"
                fontWeight={'normal'}
                textAlign="left"
              >
                No {STR_MAIN_SUBJECTS.toLowerCase()} found
              </Box>
            ) : null}
          </VStack>
        </Box>
      )}
    </div>
  );
});

export default Search;
