// adapted from
//http:stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
export function intersection(p0, p1, p2, p3) {
  var vector1 = [p1[0] - p0[0], p1[1] - p0[1]]; // poly2.x - poly1x, poly2.y - poly1.y
  var vector2 = [p3[0] - p2[0], p3[1] - p2[1]]; // point.x - center.x, point.y - center.y
  // Compute cross product
  var s, t;
  s = -vector1[1] * (p0[0] - p2[0]) + vector1[0] * (p0[1] - p3[1]);
  t = vector2[0] * (p0[1] - p2[1]) - vector2[1] * (p0[0] - p3[0]);
  s = s / (-vector2[0] * vector1[1] + vector1[0] * vector2[1]);
  t = t / (-vector2[0] * vector1[1] + vector1[0] * vector2[1]);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    // intersection coordinates
    return {
      x: p0[0] + t * vector1[0],
      y: p0[1] + t * vector1[1],
    };
  }
  return false;
}
