// IMPORTS
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';

// CONSTANTS
import { forceCollidePolygon } from '../../utils/forces';

// UTILITY
import {
  createImages,
  displayActivePaths,
  resetCoursesStyle,
  resetCourseStyle,
  resetPathStyle,
} from '../../utils/d3utils';

// THEME
// -

// COMPONENTS
import CourseInfoDrawer from '../drawer/CourseInfoDrawer';
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import ComparisonModal from '../modal/ComparisonModal';
import Paths from '../path/Paths';
import { getTooltip } from '../../utils/string';
import { useNavigate } from 'react-router-dom';
import { STR_MAIN_SUBJECTS } from '../../utils/constants';
import { scrollContentTop } from '../../utils/utils';
import Search from '../search/Search';

function Map(props) {
  // States

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [radius, setRadius] = useState(30);
  const [baseSvg, setBaseSvg] = useState({});
  const [rendered, setRendered] = useState(false);
  const [itemsReady, _setItemsReady] = useState(false);
  const [currentNode, setCurrentNode] = useState({});
  const [currentPaths, setCurrentPaths] = useState([]);
  const [compareCourses, _setCompareCourses] = useState([]);
  const [selectedCourses, _setSelectedCourses] = useState([]);
  const [isCompareActive, _setIsCompareActive] = useState(false);
  const [isSelectionActive, _setIsSelectionActive] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  // Router
  const navigate = useNavigate();

  // Media queries
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  // Refs

  // This ref is needed as the value of props and state isn't updated in d3 event listeners
  const searchRef = useRef();
  const chakraSelectRef = useRef();

  const itemsReadyRef = useRef(itemsReady);
  const setItemsReady = value => {
    itemsReadyRef.current = value;
    _setItemsReady(value);
  };

  const isCompareActiveRef = useRef(isCompareActive);
  const setIsCompareActive = value => {
    isCompareActiveRef.current = value;
    _setIsCompareActive(value);
  };

  const isSelectionActiveRef = useRef(isSelectionActive);
  const setIsSelectionActive = value => {
    isSelectionActiveRef.current = value;
    _setIsSelectionActive(value);
  };

  const compareCoursesRef = useRef(compareCourses);
  const setCompareCourses = value => {
    compareCoursesRef.current = [...value];
    _setCompareCourses([...value]);
  };

  const selectedCoursesRef = useRef(selectedCourses);
  const setSelectedCourses = value => {
    selectedCoursesRef.current = [...value];
    _setSelectedCourses([...value]);
  };

  // Hook - useEffect

  useEffect(() => {
    setSearchText(selectedCourse ? selectedCourse.label : searchText);
  }, [searchText, selectedCourse]);

  useEffect(() => {
    if (props.isCompareActive !== isCompareActive) {
      resetCoursesStyle();
      if (!props.isCompareActive) setCompareCourses([]);
      setIsCompareActive(props.isCompareActive);
    }

    if (props.isSelectionActive !== isSelectionActive) {
      resetCoursesStyle();
      displayActivePaths();
      if (!props.isSelectionActive) {
      } else {
        // Reset previously selected courses style
        selectedCoursesRef.current.forEach(selectedCourse => {
          d3.select(`#course${selectedCourse.id}`)
            .style('stroke', '#2D3748')
            .style('stroke-width', 5)
            .classed('active', true)
            .classed('export', true);
        });
      }

      setIsSelectionActive(props.isSelectionActive);
    }

    if (props.hasError) {
      return;
    }

    if (!rendered && props.data) {
      setCourseData(
        props.data[0].courses.sort((a, b) =>
          a.course_title > b.course_title
            ? 1
            : b.course_title > a.course_title
            ? -1
            : 0
        )
      );
      setRendered(true);
      createMap();
    }
  }, [
    props.hasError,
    props.data,
    compareCourses,
    props.isCompareActive,
    props.isSelectionActive,
  ]);

  // FUNCTIONS

  function closeModal() {
    setIsModalOpen(false);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  async function comparisonReady(d) {
    let courses = compareCoursesRef.current;

    setIsDrawerOpen(false);

    // Avoid same course selection
    if (!courses.filter(e => e.id === d.id).length > 0) {
      courses.push(d);
      setCompareCourses(courses);

      d3.select(`#course${d.id}`)
        .style('stroke', '#2D3748')
        .style('stroke-width', 5)
        .classed('active', true);
    }

    if (compareCoursesRef.current.length === 2) {
      setIsModalOpen(true);
    }
  }

  async function selectionReady(d) {
    let selectedCourses = selectedCoursesRef.current;

    setIsDrawerOpen(false);

    if (!(selectedCourses.filter(e => e.id === d.id).length > 0)) {
      d3.select(`#course${d.id}`)
        .style('stroke', '#2D3748')
        .style('stroke-width', 5)
        .classed('export', true);
      selectedCourses.push(d);
    } else {
      resetCourseStyle(d.id);
      d3.select(`#course${d.id}`).classed('export', false);
      selectedCourses.splice(
        selectedCourses.findIndex(a => a.id === d.id),
        1
      );
    }
    props.setSelectedCourses([...selectedCourses]);
  }

  function onSearchChange(text) {
    setSearchText(text);

    text = text.toLowerCase();

    d3.selectAll('.course').style('stroke', null).classed('matchsearch', false);
    resetCoursesStyle();

    if (text === '') {
      d3.selectAll('.course').classed('matchsearch', false);
      return;
    }

    d3.selectAll('.course').each(function (d) {
      if (
        d.course_title.toLowerCase().includes(text) ||
        d.description.toLowerCase().includes(text) ||
        d.mode.toLowerCase().includes(text) ||
        d.main_competence.toLowerCase().includes(text) ||
        d.sustainability.toLowerCase().includes(text) ||
        d.discipline.toLowerCase().includes(text) ||
        d.level.toLowerCase().includes(text) ||
        d.course_type.toLowerCase().includes(text) ||
        d.evaluation_form.toLowerCase().includes(text) ||
        d.language.toLowerCase().includes(text)
      ) {
        d3.select(this)
          .style('stroke', 'red')
          .style('stroke-width', 5)
          .classed('matchsearch', true);
      }
      return 'null';
    });
  }

  const createMap = async () => {
    if (!props.data || props.data.length === 0) navigate('/error');

    const mapData = props.data[0];

    props.setIsReady(true);
    setCurrentPaths(mapData.paths);

    var viz = {
        size: { width: mapData.width, height: mapData.height },
      },
      w = viz.size.width,
      h = viz.size.height;

    setRadius(mapData.course_radius);
    setSize({
      width: viz.size.width,
      height: viz.size.height,
    });

    // SETUP CONTAINERS

    var svgContainer = d3
      .select('.map-container')
      .attr('width', '100%')
      .attr('height', '100%')
      // // Responsive SVG needs these 2 attributes and no width and height attr.
      .attr(
        'viewBox',
        '0 0 ' +
          document.documentElement.clientWidth +
          ' ' +
          document.documentElement.clientHeight
      )
      .attr('preserveAspectRatio', 'xMinYMin meet');

    var group = svgContainer
      .append('g')
      .attr('class', 'main-group')
      .attr('width', viz.size.width)
      .attr('height', viz.size.height);

    setBaseSvg(group);

    // SETUP BACKGRUOND IMAGE, COURSE IMAGES, MARKERS

    if (!isMobile) {
      svgContainer
        .append('defs')
        .append('pattern')
        .attr('x', 0)
        .attr('y', 0)
        .attr('height', '1')
        .attr('width', '1')
        .attr('id', 'bg')
        .append('image')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', viz.size.width)
        .attr('height', viz.size.height)
        .attr('patternUnits', 'objectBoundingBox')
        .attr('preserveAspectRatio', 'none')
        .attr('xlink:href', mapData.background_image.guid)
        .style('-webkit-transform-style', 'preserve-3d');
    } else {
      // Load background images differently for mobile devices (improves performance)
      await d3.svg(mapData.background_image.guid).then(data => {
        var svgNode = data.getElementsByTagName('svg')[0];
        svgNode.classList.add('bg-image');
        d3.select('.main-group').node().append(svgNode);
        d3.select('.bg-image')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', viz.size.width)
          .attr('height', viz.size.height)
          .attr('patternUnits', 'objectBoundingBox');
      });
    }

    group
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', viz.size.width)
      .attr('height', viz.size.height)
      .style('fill', d => {
        if (!isMobile) {
          return 'url(#bg)';
        } else {
          return 'none';
        }
      });

    svgContainer.append('svg:defs').attr('class', 'marker-defs');

    let map_images = !mapData.map_images ? [] : mapData.map_images;

    createImages(
      [...map_images, mapData.default_course_image],
      mapData.course_radius * 1.65,
      mapData.course_radius * 1.65
    );

    // SETUP TOOLTIP

    d3.select('#wrapper')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .attr('id', 'tooltip')
      .style('background-color', 'white')
      .style('position', 'absolute')
      .style('top', 0)
      .style('left', 0)
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('z-index', 100)
      .style('padding', '10px')
      .style('pointer-events', 'none')
      .attr(
        'transform',
        'translate(' +
          document.documentElement.clientWidth / 2 +
          ',' +
          document.documentElement.clientHeight / 2 +
          ')'
      );

    // ZOOM MANAGEMENT

    var zoomListener = d3
      .zoom()
      .scaleExtent([0.2, 5])
      .extent([
        [0, 0],
        [
          document.documentElement.clientWidth,
          document.documentElement.clientHeight,
        ],
      ])
      .on('zoom', zoomed);

    svgContainer.call(zoomListener);

    function zoomed({ transform }) {
      group.attr('transform', transform);
    }

    // CENTERING FUNCTIONS

    // Center the view on the glancer
    function centerGlancer() {
      zoomFit(0.75, 1000);
    }

    // group

    // Zoom to fit

    function zoomFit(paddingPercent, transitionDuration) {
      var bounds = d3.select('.main-group').node().getBBox();
      var parent = d3.select('.map-container').node().parentElement;
      var fullWidth = parent.clientWidth,
        fullHeight = parent.clientHeight;
      var width = bounds.width,
        height = bounds.height;
      var midX = bounds.x + width / 2,
        midY = bounds.y + height / 2;
      if (width === 0 || height === 0) return; // nothing to fit
      var scale =
        (paddingPercent || 0.75) /
        Math.max(width / fullWidth, height / fullHeight);
      var translate = [
        fullWidth / 2 - scale * midX,
        fullHeight / 2 - scale * midY,
      ];

      d3.select('.map-container')
        .transition()
        .duration(transitionDuration || 0) // milliseconds
        .call(
          zoomListener.transform,
          d3.zoomIdentity
            .translate(translate[0], translate[1] + 35)
            .scale(scale)
        );
    }

    // center the view onto the node.
    function zoomFitPath(pathClassName, delta = 0) {
      var bounds = d3.select(`.${pathClassName}`).node().getBBox();
      if (isCompareActiveRef.current) return;

      let t = d3.zoomTransform(d3.select('.map-container').node());

      let x = bounds.x + bounds.width / 2;
      let y = bounds.y + bounds.height / 2;
      x = document.documentElement.clientWidth / 2 - x * t.k + delta / 2;
      y = document.documentElement.clientHeight / 2 - y * t.k;

      d3.select('.map-container')
        .transition()
        .duration(1000)
        .call(
          zoomListener.transform,
          d3.zoomIdentity.translate(x, y).scale(t.k)
        );
    }

    d3.select('.center-path').on('click', () => {
      zoomFitPath('learning-paths.active', -450);
    });

    // center the view onto the node.
    function centerCourse(course, delta = 0) {
      if (isCompareActiveRef.current) return;

      let t = d3.zoomTransform(d3.select('.map-container').node());

      let x = course.x;
      let y = course.y;
      x = document.documentElement.clientWidth / 2 - x * t.k + delta / 2;
      y = document.documentElement.clientHeight / 2 - y * t.k;

      d3.select('.map-container')
        .transition()
        .duration(1000)
        .call(
          zoomListener.transform,
          d3.zoomIdentity.translate(x, y).scale(t.k)
        );
    }

    // INITIALIZE CLUSTERS

    if (mapData.clusters) {
      mapData.clusters.map(function (cluster) {
        if (cluster.courses) {
          // Map courses to clusters
          var coursesList = mapData.courses.slice();
          cluster.data = cluster.courses.map(function (course, index) {
            return coursesList.find(mapCourse => mapCourse.ID === course);
          });
        } else {
          cluster.data = [];
        }

        // Extract points
        cluster.polygon = cluster.points.split('/').map(point =>
          point.split(',').map((coordinate, index) => {
            var computed = 0;
            index === 0
              ? (computed = Number(coordinate) * w)
              : (computed = Number(coordinate) * h);

            return computed;
          })
        );

        initLayout(cluster);

        return cluster;
      });
    }

    /**
     * Cluster layout initialization.
     * @param cluster Cluster with courses
     */
    function initLayout(cluster) {
      // The scale helps us to have similar size in different clusters
      var scale = d3
        .scaleLinear()
        .domain(
          d3.extent(
            cluster.data.map(function (d) {
              return 30;
            })
          )
        )
        .range([5, 15]);

      // COMPUTE POLYGON CENTROID

      var center = d3.polygonCentroid(cluster.polygon);

      // POLYGON CREATION

      var polygon = group
        .append('polygon')
        .attr('class', 'polygon' + cluster.ID)
        .attr('points', cluster.polygon)
        .attr('stroke', 'black')
        .style('fill', 'transparent')
        .attr('stroke-width', 0)
        .attr('pointer-events', 'visible')
        .style('pointer-events', 'visible')
        .style('opacity', 1)
        .on('click', function (d) {})
        .on('mouseenter', function (event, d) {
          // d3.select('#tooltip')
          //   .transition()
          //   .duration(400)
          //   .style('opacity', 1)
          //   .style('left', event.pageX + 'px')
          //   .style('top', event.pageY + 'px');
          // d3.select('#tooltip').html(
          //   getTooltip(cluster.cluster_name, cluster.cluster_description)
          // );
        })
        .on('mouseout', function (event, d) {
          // d3.select('#tooltip').transition().duration(10).style('opacity', 0);
        });

      // COURSES CREATION

      var courses = group
        .append('g')
        .attr('class', 'courses-' + cluster.ID)
        .selectAll('.course')
        .data(cluster.data)
        .enter()
        .append('circle')
        .attr('class', 'course')
        .attr('id', d => 'course' + d.id)
        .style('fill', d => {
          let firstOption = '';

          if (mapData.show_by_options && mapData.show_by_options.length > 0) {
            firstOption = mapData.show_by_options[0];
          }

          if (firstOption && d[firstOption.value]) {
            d.selectedProperty =
              `<b>${firstOption.label}</b>: ` + d[firstOption.value];
            let prefix = firstOption.value.toLowerCase().slice(0, 2);
            let suffix = d[firstOption.value].replace(' ', '-').toLowerCase();

            return `url(#${prefix}-${suffix})`;
          }

          d.selectedProperty = '';
          return 'url(#default-course-image';
        })
        .attr('r', function (d) {
          return mapData.course_radius;
        });

      d3.select('.courses-' + cluster.ID).data(cluster);

      // COURSES INTERACTION SETUP

      courses
        .on('click', function (event, d) {
          let isAnyModeActive = false;

          setCurrentNode(d);

          if (isCompareActiveRef.current) {
            isAnyModeActive = true;
            comparisonReady(d);
          }
          if (isSelectionActiveRef.current) {
            isAnyModeActive = true;
            selectionReady(d);
          }

          if (!isAnyModeActive) {
            resetCoursesStyle();
            setIsDrawerOpen(true);
            scrollContentTop('.info-drawer__content');
            centerCourse(d, -450);

            d3.select(this)
              .style('stroke', '#2D3748')
              .style('stroke-width', 5)
              .classed('active', true);
          }

          d3.select('#tooltip').transition().duration(10).style('opacity', 0);
        })
        .on('mouseenter', function (event, d) {
          d3.select(this).style('cursor', 'pointer');
          d3.select('#tooltip')
            .transition()
            .duration(400)
            .style('opacity', 1)
            .style('left', event.pageX + 'px')
            .style('top', event.pageY + 'px');
          d3.select('#tooltip').html(
            getTooltip(
              d.course_title,
              `${
                d.instructor
                  ? '<b>Instructor</b>: ' + d.instructor + '<br/>'
                  : ''
              }${d.selectedProperty ? d.selectedProperty : ''}`
            )
          );
        })
        .on('mouseout', function (event, d) {
          d3.select(this).style('cursor', 'initial');
          d3.select('#tooltip').transition().duration(10).style('opacity', 0);
        });

      // Apply forces to the courses
      var force = d3
        // Create new force simulation
        .forceSimulation(cluster.data)
        // Apply force towards the centroid of the polygon
        .force('center', d3.forceCenter(center[0], center[1]))
        // Apply counter force along the polygon edges
        .force(
          'polygonCollide',
          forceCollidePolygon(cluster.polygon)
            .radius(mapData.course_radius / 2)
            .iterations(100)
        )
        .alphaDecay(0.01)
        // Apply a small collision force on the courses, repels courses from each other
        .force(
          'collide',
          d3
            .forceCollide(mapData.course_radius * 1.2)
            .strength(0.3)
            .iterations(2)
        )
        .alphaDecay(0.01);

      force.on('tick', function () {
        courses.attr('transform', function (d) {
          return 'translate(' + d.x + ',' + d.y + ')';
        });
      });

      force.on('end', () => {
        setItemsReady(true);
      });

      let infoDrawerClose = d3.select('.info-drawer__close');
      let pathDrawerClose = d3.select('.path-drawer__close');

      let compareModalEnable = d3.select('.compare-modal__enable');
      let selectionEnable = d3.select('.selection__enable');
      let resetButton = d3.select('#reset-map');

      infoDrawerClose.on('click', () => {
        resetCoursesStyle();
        centerGlancer();
      });

      pathDrawerClose.on('click', () => {
        centerGlancer();
      });

      compareModalEnable.on('click', () => {
        setIsDrawerOpen(false);
        pathDrawerClose.dispatch('click');
        let pathDrawerCloseButton = document.querySelector(
          '.path-drawer__close'
        );
        if (pathDrawerCloseButton) pathDrawerCloseButton.click();
        resetCoursesStyle();
        // resetPathStyle();
        centerGlancer();
      });

      selectionEnable.on('click', () => {
        setIsDrawerOpen(false);
        pathDrawerClose.dispatch('click');
        let pathDrawerCloseButton = document.querySelector(
          '.path-drawer__close'
        );
        if (pathDrawerCloseButton) pathDrawerCloseButton.click();
        resetCoursesStyle();
        // resetPathStyle();
        centerGlancer();
      });

      resetButton.on('click', () => {
        // Close drawers
        setIsDrawerOpen(false);
        let pathDrawerCloseButton = document.querySelector(
          '.path-drawer__close'
        );
        if (pathDrawerCloseButton) pathDrawerCloseButton.click();
        // Reset styles
        resetPathStyle();
        resetCoursesStyle();
        // Center the glancer
        centerGlancer();
        searchRef.current.clear();
        // Empty search field
        setSearchText('');
        props.onMapReset();
      });
    }

    zoomFit();
  };

  const onItemMouseEnter = value => {
    const course = d3.select(`#course${value.id}`);
    course.style('stroke', 'red').style('stroke-width', 5);
  };
  const onItemMouseLeave = value => {
    resetCourseStyle(value.id);
  };

  return (
    <div id="wrapper">
      {props.data && props.data.length && (
        <>
          <HStack
            id="main-stack"
            position="absolute"
            top={isMobile ? '105px' : '112px'}
            maxW="calc(100% - var(--chakra-space-6) * 2)"
            spacing="var(--chakra-space-4)"
            left="var(--chakra-space-6)"
          >
            <InputGroup maxW="100%">
              <Search
                ref={searchRef}
                data={courseData}
                onItemMouseEnter={onItemMouseEnter}
                onItemMouseLeave={onItemMouseLeave}
              />
            </InputGroup>
            <Paths
              paths={currentPaths}
              closeCourseDrawer={() => {
                closeDrawer();
              }}
              disabled={
                isSelectionActiveRef.current ||
                isCompareActiveRef.current ||
                !itemsReadyRef.current
              }
            />
          </HStack>

          <CourseInfoDrawer
            data={currentNode}
            disabledFields={props.data[0].disabled_fields
              .replace(/\s/g, '')
              .split(',')}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={closeDrawer}
          />
          <ComparisonModal
            disabledFields={props.data[0].disabled_fields
              .replace(/\s/g, '')
              .split(',')}
            isOpen={isModalOpen}
            onClose={() => {
              setCompareCourses([]);
              resetCoursesStyle();
              closeModal();
            }}
            compareCourses={compareCourses}
          />
        </>
      )}
      <svg
        className="map-container"
        type="image/svg+xml"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      ></svg>
    </div>
  );
}

export default Map;
