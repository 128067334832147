import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Theme
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';

// Components
import MapContainer from './components/map/MapContainer';
import MapsList from './components/map/MapsList';

// Styles
import './App.scss';
import NoMatch from './components/error/NoMatch';
import Error from './components/error/Error';
import NoGlancer from './components/error/NoGlancer';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        {/* <Route path="/" element={<MapContainer />} /> */}
        <Route path="/" element={<MapsList />} />
        <Route path="/glancer/:slug" element={<MapContainer />} />
        <Route path="/error" element={<Error />} />
        <Route path="/noglancer" element={<NoGlancer />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
