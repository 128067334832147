import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as d3 from 'd3';

// Theme
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Heading,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Box,
  Button,
  Slide,
  Flex,
  CloseButton,
  Container,
  useToast,
  DrawerOverlay,
  useMediaQuery,
  Stack,
  Radio,
  RadioGroup,
  Text,
  Divider,
} from '@chakra-ui/react';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import { CloseIcon } from '@chakra-ui/icons';
import { useFetch } from '../../utils/useFetch';
import {
  API_DATA_BASE_URL,
  API_FILTERS_BY_ID_URL,
  API_FILTERS_URL,
  STR_MAIN_SUBJECT,
  STR_MAIN_SUBJECTS,
} from '../../utils/constants';
import ReactDatePicker from 'react-datepicker';
import { scrollContentTop } from '../../utils/utils';

const FilterDrawer = forwardRef((props, ref) => {
  const ref1 = useRef();
  const ref2 = useRef();

  const toast = useToast();

  const [rendered, setRendered] = useState(false);
  const [text, setText] = useState('');
  const [meet, setMeet] = useState('1');
  const [hasDate, setHaseDate] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [language, setLanguage] = useState('');
  const [mainCompetence, setMainCompetence] = useState('');
  const [sustainability, setSustainability] = useState('');
  const [discipline, setDiscipline] = useState('');
  const [courseType, setCourseType] = useState('');
  const [level, setLevel] = useState('');
  const [mode, setMode] = useState('');
  const [evaluationForm, setEvaluationForm] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [assessment, setAssessment] = useState('');
  const [natureOfExamination, setNatureOfExamination] = useState('');
  const [teachingActivity, setTeachingActivity] = useState('');
  const [teachingFormat, setTeachingFormat] = useState('');
  const [prerequisites, setPrerequisites] = useState('');
  const [examinationTime, setExaminationTime] = useState('');
  const [academicLevel, setAcademicLevel] = useState('');

  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  const [filtersData, loading, hasError] = useFetch(
    API_DATA_BASE_URL +
      API_FILTERS_BY_ID_URL +
      (props.data !== null && props.data.length > 0
        ? '?id=' + props.data[0].id
        : '')
  );

  useEffect(() => {
    if (!rendered) {
      if (hasError) {
        return;
      }

      setRendered(true);

      if (props.data && filtersData) {
        if (props.data[0].courses) {
          let filteredCourses = props.data[0].courses.filter(course => {
            if (course.starting_date !== '0000-00-00') {
              return true;
            }

            return false;
          });

          filteredCourses.length > 0 ? setHaseDate(true) : setHaseDate(false);
        }
      }
    }

    let title = document.querySelector('.filter-drawer__title');
    let footer = document.querySelector('.filter-drawer__footer');
    if (title && footer)
      setTitleHeight(title.offsetHeight + footer.offsetHeight);

    setIsFilterSelected(isAnyFilterSelected());
  }, [
    props.data,
    rendered,
    hasError,
    filtersData,
    startDate,
    endDate,
    language,
    mainCompetence,
    sustainability,
    discipline,
    courseType,
    level,
    mode,
    evaluationForm,
    skillLevel,
    assessment,
    natureOfExamination,
    teachingActivity,
    teachingFormat,
    prerequisites,
    examinationTime,
    academicLevel,
  ]);

  // Extend this component so that any parent can call its defined methods
  useImperativeHandle(ref, () => ({
    reset() {
      resetFilters();
    },
  }));

  function applyFilters() {
    let compliant = [];

    if (meet === '1') {
      compliant = d3.selectAll('.course').filter(meetAll);
    } else if (meet === '2') {
      compliant = d3.selectAll('.course').filter(meetAny);
    }

    d3.selectAll('.course').transition().delay(200).style('opacity', 0.3);
    compliant.transition().delay(200).style('opacity', 1);

    // Close the FilterDrawer
    props.onClose();

    if (compliant.size() === 0 && !toast.isActive(2)) {
      toast({
        id: 2,
        title: `No ${STR_MAIN_SUBJECTS.toLowerCase()} found.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else if (compliant.size() > 0 && !toast.isActive(3)) {
      toast({
        id: 3,
        title:
          'Found ' +
          compliant.size() +
          (compliant.size() > 1
            ? ` ${STR_MAIN_SUBJECTS.toLowerCase()}.`
            : ` ${STR_MAIN_SUBJECT.toLowerCase()}.`),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  /**
   * Returns true if an element meets all the conditions
   *
   * @param {Object} d
   *
   * @returns boolean
   */
  function meetAll(d) {
    if (dateRange[0] || dateRange[1]) {
      if (
        !isDateInRange(
          d.starting_date,
          d.ending_date,
          dateRange[0],
          dateRange[1]
        )
      )
        return false;
    }

    if (language) {
      if (d.language.toLowerCase() !== language.toLowerCase()) return false;
    }

    if (mainCompetence) {
      if (d.main_competence.toLowerCase() !== mainCompetence.toLowerCase())
        return false;
    }

    if (sustainability) {
      if (d.sustainability.toLowerCase() !== sustainability.toLowerCase())
        return false;
    }

    if (discipline) {
      if (d.discipline.toLowerCase() !== discipline.toLowerCase()) return false;
    }

    if (courseType) {
      if (d.course_type.toLowerCase() !== courseType.toLowerCase())
        return false;
    }

    if (level) {
      if (d.level.toLowerCase() !== level.toLowerCase()) return false;
    }

    if (mode) {
      if (d.mode.toLowerCase() !== mode.toLowerCase()) return false;
    }

    if (evaluationForm) {
      if (d.evaluation_form.toLowerCase() !== evaluationForm.toLowerCase())
        return false;
    }

    if (skillLevel) {
      if (d.skill_level.toLowerCase() !== skillLevel.toLowerCase())
        return false;
    }

    if (assessment) {
      if (d.assessment.toLowerCase() !== assessment.toLowerCase()) return false;
    }

    if (natureOfExamination) {
      if (
        d.nature_of_examination.toLowerCase() !==
        natureOfExamination.toLowerCase()
      )
        return false;
    }

    if (teachingActivity) {
      if (d.teaching_activity.toLowerCase() !== teachingActivity.toLowerCase())
        return false;
    }

    if (teachingFormat) {
      if (d.teaching_format.toLowerCase() !== teachingFormat.toLowerCase())
        return false;
    }

    if (prerequisites) {
      if (d.prerequisites.toLowerCase() !== prerequisites.toLowerCase())
        return false;
    }

    if (examinationTime) {
      if (d.examination_time.toLowerCase() !== examinationTime.toLowerCase())
        return false;
    }

    if (academicLevel) {
      if (d.academic_level.toLowerCase() !== academicLevel.toLowerCase())
        return false;
    }

    return true;
  }

  /**
   * Returns true if an element meets all the conditions
   *
   * @param {Object} d
   *
   * @returns boolean
   */
  function meetAny(d) {
    if (dateRange[0] || dateRange[1]) {
      if (
        isDateInRange(
          d.starting_date,
          d.ending_date,
          dateRange[0],
          dateRange[1]
        )
      )
        return true;
    }

    if (language) {
      if (d.language.toLowerCase() === language.toLowerCase()) return true;
    }

    if (mainCompetence) {
      if (d.main_competence.toLowerCase() === mainCompetence.toLowerCase())
        return true;
    }

    if (sustainability) {
      if (d.sustainability.toLowerCase() === sustainability.toLowerCase())
        return true;
    }

    if (discipline) {
      if (d.discipline.toLowerCase() === discipline.toLowerCase()) return true;
    }

    if (courseType) {
      if (d.course_type.toLowerCase() === courseType.toLowerCase()) return true;
    }

    if (level) {
      if (d.level.toLowerCase() === level.toLowerCase()) return true;
    }

    if (mode) {
      if (d.mode.toLowerCase() === mode.toLowerCase()) return true;
    }

    if (evaluationForm) {
      if (d.evaluation_form.toLowerCase() === evaluationForm.toLowerCase())
        return true;
    }

    if (skillLevel) {
      if (d.skill_level.toLowerCase() === skillLevel.toLowerCase()) return true;
    }

    if (assessment) {
      if (d.assessment.toLowerCase() === assessment.toLowerCase()) return true;
    }

    if (natureOfExamination) {
      if (
        d.nature_of_examination.toLowerCase() ===
        natureOfExamination.toLowerCase()
      )
        return true;
    }

    if (teachingActivity) {
      if (d.teaching_activity.toLowerCase() === teachingActivity.toLowerCase())
        return true;
    }

    if (teachingFormat) {
      if (d.teaching_format.toLowerCase() === teachingFormat.toLowerCase())
        return true;
    }

    if (prerequisites) {
      if (d.prerequisites.toLowerCase() === prerequisites.toLowerCase())
        return true;
    }

    if (examinationTime) {
      if (d.examination_time.toLowerCase() === examinationTime.toLowerCase())
        return true;
    }

    if (academicLevel) {
      if (d.academic_level.toLowerCase() === academicLevel.toLowerCase())
        return true;
    }

    return false;
  }

  /**
   * Checks if a course start and/or end date is in the range of the start and/or dates that the user specified.
   *
   * @param {Date} courseStartDate
   * @param {Date} courseEndDate
   * @param {Date} filterStartDate
   * @param {Date} filterEndDate
   * @returns boolean
   */
  function isDateInRange(
    courseStartDate,
    courseEndDate,
    filterStartDate,
    filterEndDate
  ) {
    if (courseStartDate === '0000-00-00' && courseEndDate === '0000-00-00')
      return false;

    let courseStartDateTransformed = new Date(
        new Date(courseStartDate).setHours(0, 0, 0, 0)
      ),
      courseEndDateTransformed = new Date(
        new Date(courseEndDate).setHours(0, 0, 0, 0)
      );

    // Check if greater than start date [---

    if (
      dateIsValid(courseStartDateTransformed) &&
      filterStartDate &&
      (filterEndDate === null || courseEndDate === '0000-00-00')
    ) {
      return courseStartDateTransformed >= filterStartDate;
    }

    // Check if in range [---]

    if (
      dateIsValid(courseStartDateTransformed) &&
      dateIsValid(courseEndDateTransformed)
    ) {
      return (
        courseStartDateTransformed >= filterStartDate &&
        courseEndDateTransformed <= filterEndDate
      );
    }

    // If none of the above returns, consider the dates to be in range
    return true;
  }

  function isAnyFilterSelected() {
    if (
      !text &&
      !startDate &&
      !endDate &&
      !language &&
      !mainCompetence &&
      !sustainability &&
      !discipline &&
      !courseType &&
      !level &&
      !mode &&
      !evaluationForm &&
      !skillLevel &&
      !assessment &&
      !natureOfExamination &&
      !teachingActivity &&
      !teachingFormat &&
      !prerequisites &&
      !examinationTime &&
      !academicLevel
    )
      return false;

    return true;
  }

  function dateIsValid(date) {
    return (
      date &&
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(date)
    );
  }

  function resetFilters() {
    d3.selectAll('.course').transition().delay(200).style('opacity', 1);
    setText('');
    setDateRange([null, null]);
    setLanguage('');
    setMainCompetence('');
    setSustainability('');
    setDiscipline('');
    setCourseType('');
    setLevel('');
    setMode('');
    setEvaluationForm('');
    setSkillLevel('');
    setAssessment('');
    setNatureOfExamination('');
    setTeachingActivity('');
    setTeachingFormat('');
    setPrerequisites('');
    setExaminationTime('');
    setAcademicLevel('');

    setIsFilterSelected(false);
  }

  function CustomSelect(props) {
    return (
      <>
        {props.optionsList && props.optionsList.length > 0 && (
          <div>
            <Heading as="h3" size="sm" mt="3" mb="3">
              {props.label}
            </Heading>
            <Select
              placeholder="Select option"
              value={props.state}
              onChange={event => props.setState(event.target.value)}
              mb="3"
            >
              {props.optionsList.map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              ))}
            </Select>
          </div>
        )}
      </>
    );
  }

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <InputGroup>
        <Input
          value={value}
          onChange={onChange}
          onClick={onClick}
          ref={ref}
          placeholder="Select a range"
          mb="3"
        />
        {value && (
          <InputRightElement
            onClick={() => setDateRange([null, null])}
            children={
              <CloseIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  background: '#8b8e94',
                  borderRadius: '50%',
                  padding: '2px',
                  color: 'white',
                  fontWeight: 'bold',
                  marginRight: '10px',
                }}
              />
            }
          />
        )}
      </InputGroup>
    </>
  ));

  return (
    <>
      {props.loading ? (
        <Box w="100%" h="100%">
          <Spinner color="white" size="lg" />
        </Box>
      ) : null}
      {filtersData && (
        <>
          <Slide
            direction="left"
            in={props.isOpen}
            style={{ zIndex: 10, width: '450px', maxWidth: '100%' }}
            sx={{
              zIndex: 10,
              width: '450px',
            }}
          >
            <Box
              position="relative"
              color="black"
              bg="white"
              shadow="md"
              width="450px"
              maxWidth="100%"
              height="100vh"
            >
              <Flex
                className="filter-drawer__title"
                align="start"
                p="20px"
                pt="40px"
              >
                <Heading as="h2" letterSpacing={'tighter'} maxW="100%">
                  Filter {STR_MAIN_SUBJECTS.toLowerCase()}
                </Heading>
                <CloseButton
                  className="filter-drawer__close"
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={() => {
                    scrollContentTop('.filter-drawer__content');
                    props.onClose();
                  }}
                />
              </Flex>
              <Flex
                className="filter-drawer__content"
                align="start"
                height={
                  isMobile
                    ? `calc(100vh - ${titleHeight + 80}px)`
                    : `calc(100% - ${titleHeight}px)`
                }
                overflowY="scroll"
                sx={{
                  '::-webkit-scrollbar': {
                    width: '7px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0,0,0,.3)',
                    borderRadius: '24px',
                  },
                }}
              >
                <Container
                  textAlign="left"
                  pl="20px"
                  pr="20px"
                  sx={{
                    ol: {
                      listStylePosition: 'inside',
                    },
                    'li::marker': {
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <div>
                    <Text fontSize="md">
                      Select a filter and press the apply button, the visibility
                      of the {STR_MAIN_SUBJECTS.toLowerCase()} will change
                      according to your selection.
                      <br />
                      You can choose to see the{' '}
                      {STR_MAIN_SUBJECTS.toLowerCase()} that match all or any of
                      the filters you selected.
                    </Text>
                    <Divider mt="3" mb="3" />
                    <Flex direction="row">
                      <Text fontSize="md">Match</Text>
                      <RadioGroup onChange={setMeet} value={meet} pl="2" pr="2">
                        <Stack direction="row">
                          <Radio value="1">
                            <b>All</b>
                          </Radio>
                          <Radio value="2">
                            <b>Any</b>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      <Text fontSize="md"> filters.</Text>
                    </Flex>
                    <Divider mt="3" />
                    {hasDate && (
                      <>
                        <Heading as="h3" size="sm" mt="3" mb="3">
                          Date
                        </Heading>
                        <Heading as="h4" size="xs" mt="3" mb="3">
                          Start date - end date
                        </Heading>
                        <ReactDatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={update => {
                            setDateRange(update);
                          }}
                          customInput={<CustomInput />}
                          dateFormat="dd/MM/yyyy"
                        />
                      </>
                    )}
                    {/* <Input
                      ref={ref1}
                      value={startDate}
                      onFocus={() => (ref1.current.type = 'date')}
                      onBlur={() => (ref1.current.type = 'date')}
                      onChange={onStartingDateChange}
                      placeholder="Select a date"
                      size="md"
                      backgroundColor="#ffffff"
                      type="text"
                    />
                    <Heading as="h4" size="xs" mt="3" mb="3">
                      Ending date
                    </Heading>
                    <Input
                      ref={ref2}
                      value={endDate}
                      onFocus={() => (ref2.current.type = 'date')}
                      onBlur={() => (ref2.current.type = 'date')}
                      onChange={onEndingDateChange}
                      placeholder="Select a date"
                      size="md"
                      backgroundColor="#ffffff"
                      type="text"
                      mb="3"
                    /> */}
                  </div>
                  {!props.disabledFields.includes('languages') && (
                    <CustomSelect
                      optionsList={filtersData.languages}
                      label="Language"
                      state={language}
                      setState={setLanguage}
                    />
                  )}
                  {!props.disabledFields.includes('main_competence') && (
                    <CustomSelect
                      optionsList={filtersData.maincompetences}
                      label="Main Competence"
                      state={mainCompetence}
                      setState={setMainCompetence}
                    />
                  )}
                  {!props.disabledFields.includes('sustainability') && (
                    <CustomSelect
                      optionsList={filtersData.sustainabilities}
                      label="Sustainability"
                      state={sustainability}
                      setState={setSustainability}
                    />
                  )}
                  {!props.disabledFields.includes('discipline') && (
                    <CustomSelect
                      optionsList={filtersData.disciplines}
                      label="Discipline"
                      state={discipline}
                      setState={setDiscipline}
                    />
                  )}
                  {!props.disabledFields.includes('type_of_course') && (
                    <CustomSelect
                      optionsList={filtersData.coursetypes}
                      label="Course type"
                      state={courseType}
                      setState={setCourseType}
                    />
                  )}
                  {!props.disabledFields.includes('level') && (
                    <CustomSelect
                      optionsList={filtersData.levels}
                      label="Level"
                      state={level}
                      setState={setLevel}
                    />
                  )}
                  {!props.disabledFields.includes('mode') && (
                    <CustomSelect
                      optionsList={filtersData.modes}
                      label="Format"
                      state={mode}
                      setState={setMode}
                    />
                  )}
                  {!props.disabledFields.includes('evaluation_form') && (
                    <CustomSelect
                      optionsList={filtersData.evaluationforms}
                      label="Evaluation Form"
                      state={evaluationForm}
                      setState={setEvaluationForm}
                    />
                  )}
                  {!props.disabledFields.includes('skill_level') && (
                    <CustomSelect
                      optionsList={filtersData.skilllevels}
                      label="Skill Level"
                      state={skillLevel}
                      setState={setSkillLevel}
                    />
                  )}
                  {!props.disabledFields.includes('assessment') && (
                    <CustomSelect
                      optionsList={filtersData.assessments}
                      label="Assessment"
                      state={assessment}
                      setState={setAssessment}
                    />
                  )}
                  {!props.disabledFields.includes('nature_of_examination') && (
                    <CustomSelect
                      optionsList={filtersData.natureofexaminations}
                      label="Nature of Examination"
                      state={natureOfExamination}
                      setState={setNatureOfExamination}
                    />
                  )}
                  {!props.disabledFields.includes('teaching_activity') && (
                    <CustomSelect
                      optionsList={filtersData.teachingactivities}
                      label="Teaching Activity"
                      state={teachingActivity}
                      setState={setTeachingActivity}
                    />
                  )}
                  {!props.disabledFields.includes('teaching_format') && (
                    <CustomSelect
                      optionsList={filtersData.teachingformats}
                      label="Teaching Format"
                      state={teachingFormat}
                      setState={setTeachingFormat}
                    />
                  )}
                  {!props.disabledFields.includes('prerequisites') && (
                    <CustomSelect
                      optionsList={filtersData.prerequisites}
                      label="Prerequisites"
                      state={prerequisites}
                      setState={setPrerequisites}
                    />
                  )}
                  {!props.disabledFields.includes('examination_time') && (
                    <CustomSelect
                      optionsList={filtersData.examinationtimes}
                      label="Examination Time"
                      state={examinationTime}
                      setState={setExaminationTime}
                    />
                  )}
                  {!props.disabledFields.includes('academic_level') && (
                    <CustomSelect
                      optionsList={filtersData.academiclevels}
                      label="Academic Level"
                      state={academicLevel}
                      setState={setAcademicLevel}
                    />
                  )}
                </Container>
              </Flex>
              <Flex
                className="filter-drawer__footer"
                align="center"
                justify="end"
                h="60px"
                p="20px"
              >
                <Button
                  colorScheme="gray.700"
                  background="gray.200"
                  color="black"
                  shadow="md"
                  onClick={resetFilters}
                  mr="2"
                >
                  Reset
                </Button>
                <Button
                  colorScheme="gray.700"
                  bg="gray.700"
                  color="white"
                  shadow="md"
                  isDisabled={!isFilterSelected}
                  onClick={applyFilters}
                >
                  Apply
                </Button>
              </Flex>
            </Box>
          </Slide>
        </>
      )}
    </>
  );
});

export default FilterDrawer;
