import * as d3 from 'd3';
import { intersection } from './intersection';
// inspired from http:bl.ocks.org/larsenmtl/39a028da44db9e8daf14578cb354b5cb

export function forceCollidePolygon(polygon, radius) {
  var nodes,
    n,
    iterations = 1,
    max = Math.max,
    min = Math.min;
  var absub = function (a, b) {
    return max(a, b) - min(a, b);
  };
  var center = d3.polygonCentroid(polygon);

  // took from d3-force/src/collide.js
  if (typeof radius !== 'function')
    radius = constant(radius == null ? 1 : +radius);

  // took from d3-force/src/constant.js
  function constant(x) {
    return function () {
      return x;
    };
  }
  // took from d3-force/src/jiggle.js
  function jiggle() {
    return (Math.random() - 0.5) * 1e-6;
  }

  function force() {
    for (var l = 0; l < iterations; l++) {
      for (var k = 0; k < nodes.length; k++) {
        var node = nodes[k];
        var r = radius(node);
        var px = node.x >= center[0] ? 1 : -1;
        var py = node.y >= center[1] ? 1 : -1;

        var t = [node.x + px * r, node.y + py * r]; // take the radius into consideration

        // we loop over polygon's edges to check collisions
        for (var j = 0; j < polygon.length; j++) {
          var n = j + 1 < polygon.length ? j + 1 : 0;
          var p1 = polygon[j];
          var p2 = polygon[n];
          var i = intersection(p1, p2, center, t);

          if (i) {
            node.vx = (-px / Math.sqrt(absub(i.x, t[0]) + jiggle())) * 10;
            node.vy = (-py / Math.sqrt(absub(i.y, t[1]) + jiggle())) * 10;
            node.dx = node.x;
            node.dy = node.y;

            break;
          }
        }
      }
    }
    return;
  }

  force.iterations = function (_) {
    return arguments.length ? ((iterations = +_), force) : iterations;
  };

  force.initialize = function (_) {
    n = (nodes = _).length;
  };

  force.radius = function (_) {
    return arguments.length
      ? ((radius = typeof _ === 'function' ? _ : constant(+_)), force)
      : radius;
  };
  return force;
}
