import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

// THEME
import {
  Box,
  Stack,
  Heading,
  Flex,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  InputGroup,
  Input,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { showByOptions } from '../../utils/constants';

const Header = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentSelection, setCurrentSelection] = useState('');
  const handleToggle = () => (isOpen ? onMenuClose() : onMenuOpen());

  useEffect(() => {
    if (
      props.showbyoptions &&
      props.showbyoptions.length > 0 &&
      currentSelection === ''
    ) {
      setCurrentSelection(props.showbyoptions[0].label);
    }
  }, [props.showbyoptions, currentSelection]);

  // Media queries
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  function onMenuOpen() {
    let mainMenu = document.getElementById('main-stack');

    if (mainMenu) {
      mainMenu.style.setProperty('top', '170px');
    }

    onOpen();
  }

  function onMenuClose() {
    let mainMenu = document.getElementById('main-stack');

    if (mainMenu) {
      mainMenu.style.setProperty('top', '105px');
    }

    onClose();
  }

  function fillBy(field, label) {
    d3.selectAll('.course').style('fill', d => {
      if (!d[field]) {
        d.selectedProperty = ``;
        return 'url(#default-course-image)';
      }

      let imageName = d[field].replace(' ', '-').toLowerCase();
      imageName = `${field.slice(0, 2)}-${imageName}`;
      d.selectedProperty = `<b>${label}</b>: ${d[field]}`;

      return 'url(#' + imageName + ')';
    });
  }

  function setSelection(selection, label) {
    fillBy(selection, label);
    setCurrentSelection(label);
  }

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={6}
      bg="gray.700"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        {isMobile ? (
          <Heading as="h1" size="sm" letterSpacing={'tighter'}>
            {props.mapname ? `${props.mapname.substring(0, 35)}...` : ''}
            {/* {props.mapname} */}
          </Heading>
        ) : (
          <Heading as="h1" size="lg" letterSpacing={'tighter'}>
            {props.mapname}
          </Heading>
        )}
        <div>
          {props.loading ? (
            <Box w="100%" h="100%">
              <Spinner color="white" size="md" />
            </Box>
          ) : null}
        </div>
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
        <HamburgerIcon />
      </Box>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
        width={{ base: 'full', md: 'auto' }}
        alignItems="end"
        justifyContent="end"
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
        sx={
          isMobile
            ? { zIndex: 9, maxW: '600px' }
            : { zIndex: 11, maxW: '600px' }
        }
      >
        {props.showbyoptions && props.showbyoptions.length > 0 && (
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="black"
              rightIcon={<ChevronDownIcon />}
            >
              Show by: {currentSelection}
            </MenuButton>
            <MenuList sx={{ button: { color: 'black' } }}>
              {props.showbyoptions.map(option => (
                <MenuItem
                  key={option.id}
                  onClick={() => setSelection(option.value, option.label)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
        {props.infopopover}
      </Stack>

      {/* <Box
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
        mt={{ base: 4, md: 0 }}
      >
        <ColorModeSwitcher justifySelf="flex-end" />
      </Box> */}
    </Flex>
  );
};

export default Header;
