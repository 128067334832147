import React, { useEffect } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';

// FONTS

import PoppinsLight from '../../fonts/poppins-latin/poppins-v20-latin-300.ttf';
import PoppinsRegular from '../../fonts/poppins-latin/poppins-v20-latin-regular.ttf';
import PoppinsSemiBold from '../../fonts/poppins-latin/poppins-v20-latin-500.ttf';
import PoppinsBold from '../../fonts/poppins-latin/poppins-v20-latin-600.ttf';
import PoppinsExtraBold from '../../fonts/poppins-latin/poppins-v20-latin-700.ttf';
import { STR_MAIN_SUBJECT, STR_MAIN_SUBJECTS } from '../../utils/constants';

Font.register({
  family: 'PoppinsLight',
  fonts: [{ src: PoppinsLight }],
});

Font.register({
  family: 'PoppinsRegular',
  fonts: [{ src: PoppinsRegular }],
});

Font.register({
  family: 'PoppinsSemiBold',
  fonts: [{ src: PoppinsSemiBold }],
});

Font.register({
  family: 'PoppinsBold',
  fonts: [{ src: PoppinsBold }],
});

Font.register({
  family: 'PoppinsExtraBold',
  fonts: [{ src: PoppinsExtraBold }],
});

// STYLES

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 3,
  },
  section: {
    margin: 5,
    padding: 3,
    flexGrow: 1,
  },
  courseContainer: {
    backgroundColor: '#f6f6f5',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    borderColor: 'black',
    borderWidth: StyleSheet.hairlineWidth,
    margin: 20,
  },
  courseDetails: {
    display: 'flex',
    margin: 10,
  },
  courseTitle: {
    fontFamily: 'PoppinsBold',
    fontSize: 14,
    marginBottom: 5,
    borderBottomColor: 'black',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  sectionTitle: {
    fontFamily: 'PoppinsBold',
    fontSize: 10,
    marginBottom: 5,
  },
  courseSubtitle: {
    fontSize: 9,
    fontFamily: 'PoppinsSemiBold',
    marginBottom: 4,
    padding: 5,
    color: '#2D3748',
  },
  courseSubtitle2: {
    fontFamily: 'PoppinsRegular',
    fontSize: 9,
    marginBottom: 0,
    padding: 3,
    color: '#2D3748',
  },
  courseOverview: {
    fontFamily: 'PoppinsRegular',
    fontSize: 9,
    marginLeft: 5,
    marginBottom: 5,
  },
  image: {
    height: 200,
    width: 150,
  },
  title: {
    fontFamily: 'PoppinsBold',
    fontSize: '9px',
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'start',
  },
  subtitle2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: 1,
    marginTop: 1,
  },
  subtitle_column: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  vote: {
    display: 'flex',
    flexDirection: 'row',
  },
  rating: {
    height: 10,
    width: 10,
  },
  vote_text: {
    fontSize: 10,
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: '#61C74F',
    color: '#fff',
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4,
  },
  overviewContainer: {
    minHeight: 110,
  },
  detailsFooter: {
    display: 'flex',
    flexDirection: 'row',
  },
  lang: {
    fontSize: 8,
    fontWeight: 700,
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: 'bold',
  },
});

// Create Document Component
export function SelectedCoursesDocument(props) {
  useEffect(() => {}, [props.selectedCourses]);

  function isDateValid(date) {
    return date instanceof Date && !isNaN(date);
  }
  /**
   * In orderd to correctly render the image set this rule in the server:
   * 
   <CORSConfiguration>
    <CORSRule>
      <AllowedOrigin>*</AllowedOrigin>
      <AllowedMethod>GET</AllowedMethod>
    </CORSRule>
   </CORSConfiguration>
   */

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.courseDetails}>
          {props.selectedCourses ? (
            props.selectedCourses.map((d, index) => {
              return (
                <View key={index} wrap={false} style={{ margin: '10px' }}>
                  <View style={styles.courseContainer}>
                    <View style={styles.courseDetails}>
                      <Text style={[styles.courseTitle]}>{d.course_title}</Text>
                      {!(
                        !d.instructor &&
                        !isDateValid(
                          new Date(d.starting_date).toLocaleDateString(
                            'en-GB',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }
                          )
                        ) &&
                        !isDateValid(
                          new Date(d.ending_date).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        )
                      ) && (
                        <View
                          style={[
                            styles.subtitle_column,
                            {
                              marginTop: '10px',
                              paddingTop: '10px',
                              borderTop: '1px solid black',
                            },
                          ]}
                        >
                          <Text style={styles.courseSubtitle2}>
                            <Text style={styles.title}>Instructor</Text>:{' '}
                            {d.instructor}
                          </Text>
                          <Text style={styles.courseSubtitle2}>
                            {isDateValid(
                              new Date(d.starting_date).toLocaleDateString(
                                'en-GB',
                                {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                }
                              )
                            ) && (
                              <>
                                <Text style={styles.title}>Start Date:</Text>{' '}
                                {new Date(d.starting_date).toLocaleDateString(
                                  'en-GB',
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )}
                                {' - '}
                              </>
                            )}
                            {isDateValid(
                              new Date(d.ending_date).toLocaleDateString(
                                'en-GB',
                                {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                }
                              )
                            ) && (
                              <>
                                <Text style={styles.title}>End Date:</Text>{' '}
                                {new Date(d.ending_date).toLocaleDateString(
                                  'en-GB',
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )}
                              </>
                            )}
                          </Text>
                          {d.duration && (
                            <Text style={styles.courseSubtitle}>
                              <Text style={styles.title}>Duration</Text>:{' '}
                              {d.duration}
                            </Text>
                          )}
                        </View>
                      )}
                      {d.description && (
                        <View
                          style={[
                            styles.subtitle_column,
                            {
                              marginTop: '10px',
                              paddingTop: '10px',
                              borderTop: '1px solid black',
                            },
                          ]}
                        >
                          <Text style={[styles.sectionTitle]}>
                            {STR_MAIN_SUBJECT} Description:
                          </Text>
                          <Text key={index} style={styles.courseOverview}>
                            <Html style={{ fontSize: 9 }} collapse={false}>
                              {d.description}
                            </Html>
                          </Text>
                        </View>
                      )}
                      <View
                        style={[
                          styles.subtitle_column,
                          {
                            marginTop: '10px',
                            paddingTop: '10px',
                            borderTop: '1px solid black',
                          },
                        ]}
                      >
                        <Text style={[styles.sectionTitle]}>
                          Additional Information:
                        </Text>
                        {d.language &&
                          !props.disabledFields.includes('language') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Language:</Text>{' '}
                              {d.language}
                            </Text>
                          )}
                        {d.main_competence &&
                          !props.disabledFields.includes('main_competence') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Main competence:</Text>{' '}
                              {d.main_competence}
                            </Text>
                          )}
                        {d.sustainability &&
                          !props.disabledFields.includes('sustainability') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Sustainability:</Text>{' '}
                              {d.sustainability}
                            </Text>
                          )}
                        {d.discipline &&
                          !props.disabledFields.includes('discipline') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Discipline:</Text>{' '}
                              {d.discipline}
                            </Text>
                          )}
                        {d.level && !props.disabledFields.includes('level') && (
                          <Text style={styles.courseSubtitle2}>
                            <Text style={styles.title}>Level:</Text> {d.level}
                          </Text>
                        )}
                        {d.mode && !props.disabledFields.includes('mode') && (
                          <Text style={styles.courseSubtitle2}>
                            <Text style={styles.title}>Format:</Text> {d.mode}
                          </Text>
                        )}
                        {d.evaluation_form &&
                          !props.disabledFields.includes('evaluation_form') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Evaluation Form:</Text>{' '}
                              {d.evaluation_form}
                            </Text>
                          )}
                        {d.skill_level &&
                          !props.disabledFields.includes('skill_level') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Skill Level:</Text>{' '}
                              {d.skill_level}
                            </Text>
                          )}
                        {d.assessment &&
                          !props.disabledFields.includes('assessment') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Assessment:</Text>{' '}
                              {d.assessment}
                            </Text>
                          )}
                        {d.nature_of_examination &&
                          !props.disabledFields.includes(
                            'nature_of_examination'
                          ) && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>
                                Nature of Examination:
                              </Text>{' '}
                              {d.nature_of_examination}
                            </Text>
                          )}
                        {d.teaching_activity &&
                          !props.disabledFields.includes(
                            'teaching_activity'
                          ) && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>
                                Teaching Activity:
                              </Text>{' '}
                              {d.teaching_activity}
                            </Text>
                          )}
                        {d.teaching_format &&
                          !props.disabledFields.includes('teaching_format') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Teaching Format:</Text>{' '}
                              {d.teaching_format}
                            </Text>
                          )}
                        {d.prerequisites &&
                          !props.disabledFields.includes('prerequisites') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Prerequisites:</Text>{' '}
                              {d.prerequisites}
                            </Text>
                          )}
                        {d.examination_time &&
                          !props.disabledFields.includes(
                            'examination_time'
                          ) && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>
                                Examination Time:
                              </Text>{' '}
                              {d.examination_time}
                            </Text>
                          )}
                        {d.academic_level &&
                          !props.disabledFields.includes('academic_level') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Academic Level:</Text>{' '}
                              {d.academic_level}
                            </Text>
                          )}
                        {d.credits &&
                          !props.disabledFields.includes('credits') && (
                            <Text style={styles.courseSubtitle2}>
                              <Text style={styles.title}>Credits:</Text>{' '}
                              {d.credits}
                            </Text>
                          )}
                      </View>
                    </View>
                  </View>
                </View>
              );
            })
          ) : (
            <Text style={styles.courseTitle}>
              No {STR_MAIN_SUBJECTS.toLowerCase()} selected
            </Text>
          )}
        </View>
      </Page>
    </Document>
  );
}
