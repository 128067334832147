import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function useFetch(url, opts) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [url]);

  const errorHandler = e => {
    // console.error('API CALL ERROR', e);
    setHasError(true);
    setData([]);
    setLoading(false);
  };

  const getData = async () => {
    var totalPages = 0;

    fetch(url, opts)
      .then(response => {
        totalPages = response.headers.get('X-WP-TotalPages');
        return response.json();
      })
      .then(results => {
        var allResults = results;
        for (let i = 2; i <= totalPages; i++) {
          fetch(url + '?page=' + i)
            .then(response => {
              return response.json();
            }, errorHandler)
            .then(moreresults => {
              allResults = allResults.concat(moreresults);
            }, errorHandler);
        }
        setData(allResults);
      }, errorHandler)
      .finally(d => {
        setLoading(false);
      });
  };

  return [data, loading, hasError];
}

export function useFetchGlancer(url, opts) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getData();
  }, [url]);

  const errorHandler = () => {
    console.error('API CALL ERROR');
    setHasError(true);
    setData([]);
    setLoading(false);
  };

  const getData = async () => {
    var totalPages = 0;

    fetch(url, opts)
      .then(response => {
        totalPages = response.headers.get('X-WP-TotalPages');
        return response.json();
      })
      .then(results => {
        var allResults = results;
        for (let i = 2; i <= totalPages; i++) {
          fetch(url + '?page=' + i)
            .then(response => {
              return response.json();
            }, errorHandler)
            .then(moreresults => {
              allResults = allResults.concat(moreresults);
            }, errorHandler);
        }
        setData(allResults);
        if (!allResults || allResults.length === 0) navigate('/noglancer');
      }, errorHandler)
      .finally(d => {
        setLoading(false);
      });
  };

  return [data, loading, hasError];
}
