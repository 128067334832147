import {
  Divider,
  Grid,
  GridItem,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';

function InfoPopover(props) {
  const [items, setItems] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      let filteredItems = props.data.filter(map_image => {
        if (map_image.post_content) return true;
        return false;
      });

      setItems(filteredItems);
    }
  }, [props.data, props.active]);

  return (
    <>
      {items.length > 0 && (
        <Popover placement={isMobile ? 'bottom-start' : 'left-end'}>
          <PopoverTrigger style={{ marginTop: '0px' }}>
            <IconButton
              className="open-filter"
              onFocus={e => e.preventDefault()}
              icon={<BiInfoCircle />}
              colorScheme="gray.700"
              background={props.active ? 'green.500' : 'gray.100'}
              color={props.active ? 'white' : 'gray.700'}
              style={{ marginTop: '0px' }}
              size="md"
              shadow="md"
              variant="solid"
            ></IconButton>
          </PopoverTrigger>
          <PopoverContent maxH="350px">
            <PopoverCloseButton position="fixed" mt="5px" />
            <PopoverHeader fontWeight={600} padding="3" color="gray.700">
              Legend
            </PopoverHeader>
            <PopoverBody
              overflowY="scroll"
              overflowX="hidden"
              css={{
                maxW: 'calc(100vw - 10px)',
                '&::-webkit-scrollbar': {
                  width: '7px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0,0,0,.3)',
                  borderRadius: '24px',
                },
              }}
            >
              <Grid>
                {items.map((item, index) => {
                  return (
                    <GridItem w="100%" key={item.post_title}>
                      <Grid p="2" templateColumns={'60px 1fr'}>
                        <GridItem alignSelf="center" justifySelf="center">
                          <Image src={item.guid} w="35px" h="35px" />
                        </GridItem>
                        <GridItem p="2" alignSelf="center" justifySelf="start">
                          <Text
                            textAlign="start"
                            fontSize="md"
                            color="gray.700"
                          >
                            {item.post_content}
                          </Text>
                        </GridItem>
                      </Grid>
                      {items.length - 1 !== index && <Divider w="100%" />}
                    </GridItem>
                  );
                })}
              </Grid>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}

export default InfoPopover;
