import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

// Theme
import {
  Heading,
  Slide,
  Box,
  Flex,
  CloseButton,
  Text,
  Container,
  Tag,
  VStack,
  Divider,
  transition,
  useMediaQuery,
} from '@chakra-ui/react';

// D3 utilities
import { resetCoursesStyle, resetPathStyle } from '../../utils/d3utils';
import { ArrowDownIcon } from '@chakra-ui/icons';
import CourseInfo from '../course/CourseInfo';
import PathInfo from '../path/PathInfo';
import { scrollContentTop } from '../../utils/utils';

function PathInfoDrawer(props) {
  const [titleHeight, setTitleHeight] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    d3.select('#tooltip').transition().duration(10).style('opacity', 0);
    let title = document.querySelector('.path-drawer__title');
    if (title) setTitleHeight(title.offsetHeight);
  }, [props.data]);

  return (
    <>
      <Slide
        direction="right"
        in={props.isDrawerOpen}
        style={
          isMobile
            ? { zIndex: 10, width: '450px', maxWidth: '100%' }
            : { zIndex: 10, width: '450px', maxWidth: '100%', top: '88px' }
        }
        sx={{
          zIndex: 10,
          width: '450px',
        }}
      >
        <Box
          id="course-info-drawer-container"
          position="relative"
          color="black"
          bg="white"
          shadow="md"
          width="450px"
          maxWidth="100%"
          height={isMobile ? '100vh' : 'calc(100vh - 88px)'}
        >
          <Flex className="path-drawer__title" align="start" p="20px" pt="40px">
            {props.data.path_name && (
              <Heading as="h2" letterSpacing={'tighter'} maxW="100%">
                {props.data.path_name}
              </Heading>
            )}
            <CloseButton
              className="path-drawer__close"
              colorScheme="gray.700"
              position="absolute"
              top="10px"
              right="10px"
              onClick={() => {
                scrollContentTop('.path-drawer__content');
                props.closeDrawer();
              }}
            />
          </Flex>
          <Flex
            className="path-drawer__content"
            align="start"
            height={
              isMobile
                ? `calc(100vh - ${titleHeight + 80}px)`
                : `calc(100vh - ${titleHeight + 105}px)`
            }
            overflowY="scroll"
            sx={{
              '::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0,0,0,.3)',
                borderRadius: '24px',
              },
            }}
          >
            <PathInfo data={props.data} />
          </Flex>
        </Box>
      </Slide>
    </>
  );
}

export default PathInfoDrawer;
