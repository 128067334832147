// API CONSTANTS

const BASE_URL = 'https://courseglancer-admin.visual-literacy.org';
export const API_BASE_URL = `${BASE_URL}/wp-json/wp/v2`;
export const API_DATA_BASE_URL = `${BASE_URL}/wp-json/data/v1`;

export const API_GLANCERS_MIN = '/glancers-min';
export const API_GLANCER_FILTER_BY_SLUG = '/glancer?slug=';
export const API_MAPS_URL = '/glancer?per_page=100';
export const API_FILTERS_URL = '/filters';
export const API_FILTERS_BY_ID_URL = '/filter';

// LABELS

export const STR_MAIN_SUBJECT = 'Course';
export const STR_MAIN_SUBJECTS = 'Courses';
export const STR_PATH = 'Paths';
export const STR_PATH_TYPE_1 = 'Learning';
export const STR_PATH_TYPE_2 = 'Conceptual';

export const showByOptions = [
  {
    field: 'main_competence',
    label: 'Competence',
  },
  {
    field: 'discipline',
    label: 'Discipline',
  },
  {
    field: 'evaluation_form',
    label: 'Evaluation',
  },
  {
    field: 'level',
    label: 'Level',
  },
  {
    field: 'sustainability',
    label: 'Sustainability',
  },
];

// FORMATTING

export const formattingElements = [
  '<b>',
  '</b>',
  '<strong>',
  '</strong>',
  '<i>',
  '</i>',
  '<em>',
  '</em>',
  '<mark>',
  '</mark>',
  '<small>',
  '</small>',
  '<del>',
  '</del>',
  '<ins>',
  '</ins>',
  '<sub>',
  '</sub>',
  '<sup>',
  '</sup>',
  '<span>',
  '</span>',
  '<p>',
  '</p>',
  '&nbsp;',
  '&nbsp',
  '&amp;',
  '&amp',
];
