import {
  Container,
  Divider,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { BiFile } from 'react-icons/bi';
import { STR_MAIN_SUBJECT } from '../../utils/constants';
import { isValidDate } from '../../utils/data';

function CourseInfo(props) {
  var startingDate = '';
  var endingDate = '';
  var files = [];

  useEffect(() => {}, [props.data, props.searchText]);

  if (props.data !== undefined) {
    if (props.data.starting_date && props.data.startingDate !== '0000-00-00') {
      startingDate = new Date(props.data.starting_date).toLocaleDateString(
        'en-GB',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      );
    } else {
      startingDate = '';
    }

    if (props.data.ending_date && props.data.ending_date !== '0000-00-00') {
      endingDate = new Date(props.data.ending_date).toLocaleDateString(
        'en-GB',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      );
    } else {
      endingDate = '';
    }

    setupFiles();
  } else {
    return <></>;
  }

  function setupFiles() {
    if (!props.data.files) return;

    files = props.data.files.map(file => {
      let firstSplit = file.split('/');
      let secondSplit = firstSplit[firstSplit.length - 1].split('.');

      return { label: secondSplit[0], guid: file };
    });
  }

  return (
    <Container
      className="course-description"
      textAlign="left"
      pl="20px"
      pr="20px"
      sx={{
        ol: {
          listStylePosition: 'inside',
        },
        'li::marker': {
          fontWeight: 'bold',
        },
      }}
    >
      {props.data.course_image &&
        !props.disabledFields.includes('course_image') && (
          <Image
            borderRadius="15px"
            w="100%"
            h="300px"
            objectFit="contain"
            src={props.data.course_image.toString()}
            alt="Course Image"
            mb="4"
          />
        )}
      {props.data.instructor && (
        <Text fontSize="md">
          <b>Instructor:</b> {props.data.instructor}
        </Text>
      )}
      {startingDate && endingDate && (
        <>
          <Text fontSize="sm">
            <b>
              {startingDate} - {endingDate}
            </b>
          </Text>
        </>
      )}
      {props.data.duration && (
        <>
          <Text fontSize="sm">
            <b>Duration:</b> {props.data.duration}
          </Text>
        </>
      )}
      <Divider
        direction="horizontal"
        mt={4}
        mb={4}
        borderColor="rgba(0,0,0,.5)"
      />
      {props.data.description && (
        <>
          <Heading as="h3" size="md">
            {STR_MAIN_SUBJECT} Description
          </Heading>
          <Text
            pb={4}
            pt={4}
            sx={{
              'ul > li': { listStylePosition: 'inside' },
              iframe: {
                maxWidth: '100%',
                // height: 'auto',
              },
              whiteSpace: 'pre-line',
            }}
            fontSize="md"
            dangerouslySetInnerHTML={{
              __html: props.data.description,
            }}
          ></Text>
        </>
      )}
      <Divider direction="horizontal" mb={4} borderColor="rgba(0,0,0,.5)" />
      <Heading as="h3" size="md" mb={4}>
        Additional Information
      </Heading>
      {props.data.language && !props.disabledFields.includes('language') && (
        <>
          <Text fontSize="md">
            <b>Language:</b> {props.data.language}
          </Text>
        </>
      )}
      {props.data.main_competence &&
        !props.disabledFields.includes('main_competence') && (
          <Text fontSize="md">
            <b>Main Compentence:</b> {props.data.main_competence}
          </Text>
        )}
      {props.data.sustainability &&
        !props.disabledFields.includes('sustainability') && (
          <Text fontSize="md">
            <b>Sustainability:</b> {props.data.sustainability}
          </Text>
        )}
      {props.data.discipline &&
        !props.disabledFields.includes('discipline') && (
          <>
            <Text fontSize="md">
              <b>Discipline:</b> {props.data.discipline}
            </Text>
          </>
        )}
      {props.data.type_of_course &&
        !props.disabledFields.includes('type_of_course') && (
          <Text fontSize="md">
            <b>Type of course:</b> {props.data.type_of_course}
          </Text>
        )}
      {props.data.level && !props.disabledFields.includes('level') && (
        <Text fontSize="md">
          <b>Level:</b> {props.data.level}
        </Text>
      )}
      {props.data.mode && !props.disabledFields.includes('mode') && (
        <Text fontSize="md">
          <b>Format:</b> {props.data.mode}
        </Text>
      )}
      {props.data.evaluation_form &&
        !props.disabledFields.includes('evaluation_form') && (
          <Text fontSize="md">
            <b>Evaluation Form:</b> {props.data.evaluation_form}
          </Text>
        )}
      {props.data.credits && !props.disabledFields.includes('credits') && (
        <>
          <Text fontSize="md">
            <b>Credits:</b> {props.data.credits}
          </Text>
        </>
      )}
      {props.data.skill_level &&
        !props.disabledFields.includes('skill_level') && (
          <>
            <Text fontSize="md">
              <b>Skill Level:</b> {props.data.skill_level}
            </Text>
          </>
        )}
      {props.data.assessment &&
        !props.disabledFields.includes('assessment') && (
          <>
            <Text fontSize="md">
              <b>Assessment:</b> {props.data.assessment}
            </Text>
          </>
        )}
      {props.data.nature_of_examination &&
        !props.disabledFields.includes('nature_of_examination') && (
          <>
            <Text fontSize="md">
              <b>Nature of Examination:</b> {props.data.nature_of_examination}
            </Text>
          </>
        )}
      {props.data.teaching_activity &&
        !props.disabledFields.includes('teaching_activity') && (
          <>
            <Text fontSize="md">
              <b>Teaching Activity:</b> {props.data.teaching_activity}
            </Text>
          </>
        )}
      {props.data.teaching_format &&
        !props.disabledFields.includes('teaching_format') && (
          <>
            <Text fontSize="md">
              <b>Teaching Format:</b> {props.data.teaching_format}
            </Text>
          </>
        )}
      {props.data.prerequisites &&
        !props.disabledFields.includes('prerequisites') && (
          <>
            <Text fontSize="md">
              <b>Prerequisites:</b> {props.data.prerequisites}
            </Text>
          </>
        )}
      {props.data.examination_time &&
        !props.disabledFields.includes('examination_time') && (
          <>
            <Text fontSize="md">
              <b>Examination Time:</b> {props.data.examination_time}
            </Text>
          </>
        )}
      {props.data.academic_level &&
        !props.disabledFields.includes('academic_level') && (
          <>
            <Text fontSize="md">
              <b>Academic Level:</b> {props.data.academic_level}
            </Text>
          </>
        )}
      {files && files.length > 0 && !props.disabledFields.includes('files') && (
        <>
          <Divider
            direction="horizontal"
            mt={4}
            mb={4}
            borderColor="rgba(0,0,0,.5)"
          />
          <Heading as="h4" size="md" mb="2">
            Documents
          </Heading>
          <List>
            {files.map((file, index) => {
              return (
                <ListItem key={index}>
                  <Link href={file.guid} isExternal fontSize="16px">
                    <ListIcon as={BiFile} w="20px" h="20px" color="gray.700" />
                    {file.label}
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
      {/* <Divider direction="horizontal" mt={4} borderColor="rgba(0,0,0,.5)" /> */}
    </Container>
  );
}

export default CourseInfo;
