import { Center, Flex, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

function Error() {
  return (
    <>
      <Flex direction="column" bg="gray.700" h="100vh">
        <Center w="100" h="100" m="5">
          <Heading as="h1" color="white">
            Page Not Found
          </Heading>
        </Center>
      </Flex>
    </>
  );
}

export default Error;
