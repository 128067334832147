import React, { useEffect, useRef, useState } from 'react';

// Theme
import {
  ChakraProvider,
  Box,
  Grid,
  Button,
  useDisclosure,
  useBoolean,
  useToast,
  Tooltip,
  IconButton,
  HStack,
  useMediaQuery,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';

// Utility
import { saveAs } from 'file-saver';
import { useFetch, useFetchGlancer } from '../../utils/useFetch';

// Components
import Map from './Map';
import FilterDrawer from '../drawer/FilterDrawer';
import Header from '../header/Header';
import {
  BiDownload,
  BiFilterAlt,
  BiInfoCircle,
  BiRefresh,
  BiSelectMultiple,
} from 'react-icons/bi';
import { MdCompare } from 'react-icons/md';
import { SelectedCoursesDocument } from '../pdf/SelectedCoursesDocument';

// Constants
import {
  API_BASE_URL,
  API_GLANCERS_MIN,
  API_GLANCER_FILTER_BY_SLUG,
  API_MAPS_URL,
  STR_MAIN_SUBJECTS,
} from '../../utils/constants';
import { pdf } from '@react-pdf/renderer';
import theme from '../../theme/theme';
import { useParams } from 'react-router-dom';
import InfoPopover from '../popovers/InfoPopover';

function MapContainer() {
  let { slug } = useParams();
  // const [data, loading, hasError] = useFetchGlancer(API_BASE_URL + API_MAPS_URL);
  const [data, loading, hasError] = useFetchGlancer(
    API_BASE_URL + API_GLANCER_FILTER_BY_SLUG + slug
  );

  localStorage.setItem('chakra-ui-color-mode', 'light'); // set default color scheme to dark
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCompareActive, setIsCompareActive] = useBoolean(false);
  const [isSelectionActive, setIsSelectionActive] = useBoolean(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  const [isReady, setIsReady] = useState(false);
  const toast = useToast();

  // Refs
  const filterDrawerRef = useRef();

  useEffect(() => {
    if (isCompareActive && !toast.isActive(1)) {
      toast({
        id: 1,
        title: 'Comparison mode enabled',
        description: `Select two ${STR_MAIN_SUBJECTS.toLowerCase()} in a row to make a comparison.`,
        status: 'info',
        duration: 4000,
        isClosable: true,
        mb: '24px',
      });
    }
    if (isSelectionActive && !toast.isActive(1)) {
      toast({
        id: 1,
        title: 'Selection mode enabled',
        description: `Select the ${STR_MAIN_SUBJECTS.toLowerCase()} you would like to export then press the download button to get a PDF of your selection.`,
        status: 'info',
        duration: 4000,
        isClosable: true,
        mb: '24px',
      });
    }
  }, [isCompareActive, isSelectionActive]);

  function addedElement(newArray) {
    setSelectedCourses(newArray);
  }

  function openFilterDrawer() {
    onOpen();
  }

  async function generatePdfDocument(data, fileName) {
    const blob = await pdf(
      <SelectedCoursesDocument
        selectedCourses={data.selectedCourses}
        disabledFields={data.disabledFields}
      />
    ).toBlob();
    saveAs(blob, fileName);
  }

  function onMapReset() {
    console.log('RESETTING MAP');
    filterDrawerRef.current.reset();
  }

  return (
    <ChakraProvider theme={theme}>
      <Header
        id="header"
        position="absolute"
        w="100%"
        loading={loading ? 1 : 0}
        mapname={data ? data[0].map_name : ''}
        showbyoptions={
          data && data[0].show_by_options ? data[0].show_by_options : undefined
        }
        infopopover={
          <InfoPopover
            disabled={isSelectionActive}
            visible={isSelectionActive}
            data={data && data.length > 0 ? data[0].map_images : []}
          />
        }
        search={{ searchText, setSearchText }}
      />
      <Box textAlign="center" fontSize="xl" boxSizing="border-box">
        <Grid
          minH="100%"
          sx={{
            minHeight: 'calc(100vh - calc(100vh - 100%))',
            height: 'calc(100vh - calc(100vh - 100%))',
          }}
        >
          <FilterDrawer
            placement="left"
            data={data}
            disabledFields={
              data && data[0].disabled_fields
                ? data[0].disabled_fields.replace(/\s/g, '').split(',')
                : []
            }
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            ref={filterDrawerRef}
          />
          <Map
            name="CourseGlancer"
            data={data}
            loading={loading ? 1 : 0}
            hasError={hasError}
            searchText={searchText}
            isCompareActive={isCompareActive}
            isSelectionActive={isSelectionActive}
            setSelectedCourses={addedElement}
            setIsReady={setIsReady}
            onMapReset={onMapReset}
          />
        </Grid>
        <VStack>
          <HStack
            position="absolute"
            bottom="calc(var(--chakra-space-6) * 3 + 5px)"
            left="var(--chakra-space-6)"
            spacing="var(--chakra-space-4)"
            style={isReady ? { display: 'flex' } : { display: 'none' }}
          >
            <Tooltip
              label="Reset"
              hasArrow
              color="white"
              isDisabled={isMobile}
              placement="top"
              fontSize="md"
            >
              <IconButton
                id="reset-map"
                onFocus={e => e.preventDefault()}
                icon={<BiRefresh />}
                colorScheme="gray.100"
                background="gray.100"
                size="md"
                color="gray.700"
                shadow="md"
                variant="solid"
              ></IconButton>
            </Tooltip>
          </HStack>
          <HStack
            position="absolute"
            bottom="var(--chakra-space-6)"
            left="var(--chakra-space-6)"
            spacing="var(--chakra-space-4)"
            style={isReady ? { display: 'flex' } : { display: 'none' }}
          >
            <Tooltip
              label="Filters"
              hasArrow
              color="white"
              isDisabled={isMobile}
              fontSize="md"
            >
              <IconButton
                className="open-filter"
                onClick={openFilterDrawer}
                onFocus={e => e.preventDefault()}
                icon={<BiFilterAlt />}
                colorScheme="gray.700"
                background="gray.700"
                size="md"
                color="white"
                shadow="md"
                variant="solid"
              ></IconButton>
            </Tooltip>
            <Button
              className="compare-modal__enable"
              onClick={setIsCompareActive.toggle}
              disabled={isSelectionActive}
              leftIcon={<MdCompare />}
              colorScheme="gray.700"
              background={isCompareActive ? 'green.500' : 'gray.100'}
              color={isCompareActive ? 'white' : 'gray.700'}
              shadow="md"
              variant="solid"
              size="md"
            >
              {isCompareActive ? 'Comparison' : 'Comparison'}
            </Button>
            <IconButton
              className="selection__enable"
              onClick={setIsSelectionActive.toggle}
              disabled={isCompareActive}
              icon={<BiSelectMultiple />}
              colorScheme="gray.700"
              background={isSelectionActive ? 'green.500' : 'gray.100'}
              color={isSelectionActive ? 'white' : 'gray.700'}
              size="md"
              shadow="md"
              variant="solid"
            ></IconButton>
            {selectedCourses.length !== 0 && isSelectionActive && (
              <div>
                <IconButton
                  onClick={() =>
                    generatePdfDocument(
                      {
                        selectedCourses,
                        disabledFields:
                          data && data[0].disabled_fields
                            ? data[0].disabled_fields
                                .replace(/\s/g, '')
                                .split(',')
                            : [],
                      },
                      'courses_export'
                    )
                  }
                  icon={<BiDownload />}
                  colorScheme="gray.700"
                  backgroundColor="gray.700"
                  size="md"
                  color="white"
                  shadow="md"
                  variant="solid"
                ></IconButton>
                <div
                  style={{
                    width: '18px',
                    height: '18px',
                    position: 'absolute',
                    backgroundColor: 'white',
                    borderRadius: '100%',
                    fontSize: '11px',
                    border: '1px solid #2D3748',
                    top: '-6px',
                    right: '-6px',
                    color: '#2D3748',
                    fontWeight: 'bold',
                  }}
                >
                  {selectedCourses.length}
                </div>
              </div>
              // <PDFDownloadLink
              //   selectedCourses={selectedCourses}
              //   document={<SelectedCoursesDocument />}
              //   fileName="courses_export.pdf"
              // >
              //   {({ blob, url, loading, error }) =>
              //     loading ? 'Loading document...' : 'Download now!'
              //   }
              // </PDFDownloadLink>
            )}
          </HStack>
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default MapContainer;
