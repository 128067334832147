import * as d3 from 'd3';

import {
  Container,
  Divider,
  Heading,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ArrowDownIcon } from '@chakra-ui/icons';
import { STR_MAIN_SUBJECTS } from '../../utils/constants';

function PathInfo(props) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getCourseData();
  }, [props.data]);

  function getCourseData() {
    if (!props.data || !props.data.courses) return [];

    let courses = props.data.courses.map(course => {
      let courseData = d3.select('#course' + course).data();

      return courseData[0];
    });

    setCourses(courses);

    return courses;
  }

  function onCourseClick(courseId) {
    let courseElement = d3.select('#course' + courseId);
    if (!courseElement.empty()) courseElement.dispatch('click');
  }

  function onCourseEnter(courseId) {
    let courseElement = d3.select('#course' + courseId);
    courseElement.transition().duration(200).style('stroke-width', '5');
  }

  function onCourseLeave(courseId) {
    let courseElement = d3.select('#course' + courseId);
    if (courseElement.classed('active')) return;
    courseElement.transition().duration(200).style('stroke-width', '1');
  }

  return (
    <Container
      className="path-description"
      textAlign="left"
      pl="20px"
      pr="20px"
      pb="20px"
      sx={{
        ol: {
          listStylePosition: 'inside',
        },
        'li::marker': {
          fontWeight: 'bold',
        },
      }}
    >
      {props.data.path_description && (
        <>
          <Text
            pb={4}
            pt={4}
            sx={{
              'ul > li': { listStylePosition: 'inside' },
              iframe: {
                maxWidth: '100%',
                // height: 'auto',
              },
              whiteSpace: 'pre-line',
            }}
            fontSize="md"
            dangerouslySetInnerHTML={{
              __html: props.data.path_description,
            }}
          ></Text>
        </>
      )}
      <Divider
        direction="horizontal"
        mt={4}
        mb={4}
        borderColor="rgba(0,0,0,.5)"
      />
      {courses && (
        <>
          <Heading as="h3" size="md" pt={4}>
            {STR_MAIN_SUBJECTS}:
          </Heading>
          <VStack spacing={4} mt={6}>
            {courses.map((course, index) => (
              <VStack key={index}>
                <Tag
                  size={'lg'}
                  variant="solid"
                  backgroundColor="gray.700"
                  colorScheme="gray.700"
                  color="white"
                  cursor="pointer"
                  textAlign="center"
                  p={2}
                  onClick={() => onCourseClick(course.id)}
                  onMouseEnter={() => onCourseEnter(course.id)}
                  onMouseLeave={() => onCourseLeave(course.id)}
                >
                  {course.course_title}
                </Tag>
                {props.data.is_conceptual !== '1'
                  ? index !== courses.length - 1 && <ArrowDownIcon />
                  : null}
              </VStack>
            ))}
          </VStack>
        </>
      )}
    </Container>
  );
}

export default PathInfo;
