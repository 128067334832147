import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { API_DATA_BASE_URL, API_GLANCERS_MIN } from '../../utils/constants';
import { useFetch } from '../../utils/useFetch';

// Theme
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link as ChakraLink,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';

// Images
import conceptGlancerImage from '../../assets/conceptglancer.png';

export default function MapsList() {
  const [data, loading, hasError] = useFetch(
    API_DATA_BASE_URL + API_GLANCERS_MIN
  );
  const [rendered, setRendered] = useState(false);

  // Media queries
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    if (!rendered && data) {
      setRendered(true);
    }
  }, [data, rendered]);

  function renderGridItem(glancer) {
    if (glancer.active === '1') {
      return (
        <GridItem w="100%" justifySelf="center" key={glancer.id}>
          <ChakraLink
            as={Link}
            to={`/glancer/${glancer.slug}`}
            sx={{ '&:hover': { textDecoration: 'none' } }}
          >
            <Box
              borderRadius="xl"
              shadow="md"
              border="1px solid var(--chakra-colors-gray-200)"
              overflow="hidden"
            >
              <Image
                src={glancer.background_image.guid}
                objectFit="cover"
                h="250px"
                w="100%"
                alt=""
              />
              <Box p="6" bg="white">
                <Box
                  mt="1"
                  mb="3"
                  fontWeight="semibold"
                  fontSize="xl"
                  as="h4"
                  noOfLines={1}
                >
                  {glancer.title}
                </Box>
              </Box>
            </Box>
          </ChakraLink>
        </GridItem>
      );
    }
    return null;
  }

  function renderExternalGridItem() {
    return (
      <GridItem w="100%" justifySelf="center" key={Math.random()}>
        <a
          as={Link}
          target="_blank"
          rel="noreferrer"
          href={`https://concept-glancer.netlify.app/glancer/conceptglancer`}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Box
            borderRadius="xl"
            shadow="md"
            border="1px solid var(--chakra-colors-gray-200)"
            overflow="hidden"
          >
            <Image
              src={conceptGlancerImage}
              objectFit="cover"
              h="250px"
              w="100%"
              alt=""
            />
            <Box p="6" bg="white">
              <Box
                mt="1"
                mb="3"
                fontWeight="semibold"
                fontSize="xl"
                as="h4"
                noOfLines={1}
              >
                Concept Glancer
              </Box>
            </Box>
          </Box>
        </a>
      </GridItem>
    );
  }

  return (
    <>
      <Flex direction="column" h="100vh">
        <Center w="100" h="100" bg="gray.700" color="white">
          <Heading as="h1">Available Glancers</Heading>
        </Center>
        {loading ? (
          <Box textAlign="center" w="100%" m="1rem 0">
            <Spinner color="gray.700" size="md" />
          </Box>
        ) : null}
        <Center m="10">
          <Grid
            w="100%"
            maxW="1200px"
            m={isMobile ? '30px' : '0'}
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            }}
            justifyContent="center"
            alignItems="center"
            gridGap="6"
          >
            {renderExternalGridItem()}
            {data && data.map(glancer => renderGridItem(glancer))}
          </Grid>
        </Center>
      </Flex>
    </>
  );
}
